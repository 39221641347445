import { Icons } from "assets";
import { Button, Dropdown, Search } from "components";
import React, { FC } from "react";
import { ETypeSearch } from "types";
import {
	BUTTON_TYPE,
	durationOptions,
	searchUserOption,
	typePlanOptions,
} from "utils";

interface ISearchUserProps {
	searchOption: ETypeSearch;
	setSearchOption: (option: ETypeSearch) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	handleSearch: () => void;
	handleResetSearch: () => void;
}

const SearchUser: FC<ISearchUserProps> = ({
	searchOption,
	setSearchOption,
	searchValue,
	setSearchValue,
	handleSearch,
	handleResetSearch,
}) => {
	return (
		<div className="flex flex-row justify-end px-4 mb-4 gap-2 ">
			<div className="w-fit h-[42px]">
				<Dropdown
					options={searchUserOption}
					value={searchOption}
					onChange={(v: any) => {
						setSearchOption(v);
						setSearchValue("");
					}}
				/>
			</div>
			{(searchOption === ETypeSearch.ID ||
				searchOption === ETypeSearch.EMAIL ||
				searchOption === ETypeSearch.NAME) && (
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Email, User name"
				/>
			)}

			{searchOption === ETypeSearch.PLAN && (
				<Dropdown
					options={typePlanOptions}
					value={searchValue}
					onChange={(v: any) => setSearchValue(v)}
				/>
			)}
			{searchOption === ETypeSearch.DURATION && (
				<Dropdown
					options={durationOptions}
					value={searchValue}
					onChange={(v: any) => setSearchValue(v)}
				/>
			)}
			<Button onClick={handleSearch}>Search</Button>
			{(searchOption === ETypeSearch.PLAN ||
				searchOption === ETypeSearch.DURATION) && (
				<div title="Reset Search">
					<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
						{Icons.Reload()}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SearchUser;
