import { Icons } from "assets";
import React from "react";

interface ITableHeaderProps {
	dataHeader: any;
	children?: React.ReactNode;
	onSorting: (column: string) => void;
	sortingColumn: any[];
}

const TableHeader = ({
	dataHeader,
	onSorting,
	sortingColumn,
}: ITableHeaderProps) => {
	return (
		<>
			{dataHeader.map((header: any, key: number) => (
				<th
					key={`${key}-${header.value}`}
					className="min-w-[140px] py-3 px-4 font-medium text-white dark:text-white bg-header hover:bg-opacity-95">
					<div
						className="flex items-center cursor-pointer"
						onClick={() => header.sort && onSorting(header?.value)}>
						{header.name}
						{header.sort && (
							<span
								className={`ml-2 bg-white ${
									sortingColumn?.includes(header.value)
										? "rotate-180"
										: "rotate-0"
								}`}>
								{Icons.ArrowDown()}
							</span>
						)}
					</div>
				</th>
			))}
		</>
	);
};

export default TableHeader;
