import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components";
import { useDashboard, useLayout } from "hooks";
import React, { useEffect, useState } from "react";
import { FormEditRefferal } from "types";
import { BUTTON_TYPE, FORM_ACTION, referralSchema } from "utils";

interface IModalEditRefrralProps {
	item: any;
	onClose: () => void;
	action: string;
	onConfirm: (formData: FormEditRefferal) => void;
}

const modalEditRefrral = ({
	item,
	onClose,
	action,
	onConfirm,
}: IModalEditRefrralProps) => {
	const { editReferralMutation } = useDashboard();
	const { openModalReferral, setOpenModalReferral } = useLayout();
	const [formData, setFormData] = useState<FormEditRefferal>({
		refferralId: "",
		code: "",
		type: "",
		active: [],
	});
	const [errors, setErrors] = useState<object>({});

	const isLoading = editReferralMutation.isPending;

	useEffect(() => {
		if (action === FORM_ACTION.EDIT && item) {
			setFormData({
				refferralId: item.id,
				code: item.code,
				type: item.type,
				active: item.active.join(", "),
			});
		} else {
			setFormData({
				refferralId: "",
				code: "",
				type: "",
				active: [],
			});
		}
	}, [item, openModalReferral]);

	const handleSubmit = async () => {
		try {
			await referralSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<>
			{openModalReferral && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalReferral(false)}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Referral"
									: "Edit Referral"}
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Type"
									type="text"
									placeholder="Enter type"
									name="type"
									formData={formData}
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Code"
									type="text"
									placeholder="Enter code"
									name="code"
									formData={formData}
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Active"
									type="text"
									formData={formData}
									placeholder="Enter active"
									name="active"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={onClose}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalEditRefrral;
