/** @format */

import React from "react";

import { SvgIconCus } from "components";
import { ISVGIcon } from "types";
import { mainColor } from "theme";

export const Icons = {
	Prev: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none">
			<path
				d="M16.5 8L1.5 8M1.5 8L7.33333 1.75M1.5 8L7.33333 14.25"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Next: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none">
			<path
				d="M1.5 8L16.5 8M16.5 8L10.6667 1.75M16.5 8L10.6667 14.25"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Close: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			{/* <rect width="24" height="24" rx="12" fill={mainColor.primary} /> */}
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L12 10.9393L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L13.0607 12L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L12 13.0607L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L10.9393 12L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
				fill={mainColor.iconDarkBlue}
			/>
		</svg>
	),
	RightIcon: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="12"
			viewBox="0 0 14 12"
			fill="none">
			<path
				d="M1 6L13 6M13 6L8.33333 1M13 6L8.33333 11"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	ArrowRightCus: ({
		width,
		height,
		size = 24,
		wrapColor,
		color,
		viewBox = "0 0 24 24",
		...props
	}: ISVGIcon) => (
		<SvgIconCus
			width={width}
			size={size}
			height={height}
			wrapColor={wrapColor}
			viewBox={viewBox}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
				fill={color ?? mainColor.iconGray}
				{...props}
			/>
		</SvgIconCus>
	),
	ArrowRight: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M2.5 10L17.5 10M17.5 10L11.6667 3.75M17.5 10L11.6667 16.25"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	ArrowLeft: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none">
			<path
				d="M18.3672 10L3.36719 10M3.36719 10L9.20052 3.75M3.36719 10L9.20052 16.25"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	ArrowDown: (color?: string) => (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4401_21665)">
				<path
					d="M10.5867 6.19305L7.99999 8.77971L5.41332 6.19305C5.15332 5.93305 4.73332 5.93305 4.47332 6.19305C4.21332 6.45305 4.21332 6.87305 4.47332 7.13305L7.53332 10.193C7.79332 10.453 8.21332 10.453 8.47332 10.193L11.5333 7.13305C11.7933 6.87305 11.7933 6.45305 11.5333 6.19305C11.2733 5.93971 10.8467 5.93305 10.5867 6.19305Z"
					fill={color || mainColor.iconBlack}
				/>
			</g>
			<defs>
				<clipPath id="clip0_4401_21665">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	EyeHide: () => (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.53009 2.46967C3.23719 2.17678 2.76232 2.17678 2.46943 2.46967C2.17653 2.76256 2.17653 3.23744 2.46943 3.53033L4.58578 5.64668C3.61207 6.32254 2.87547 7.12109 2.35985 7.90076C1.75793 8.81095 1.4165 9.75949 1.4165 10.5C1.4165 11.0782 1.62448 11.7725 1.98309 12.461C2.34869 13.163 2.8965 13.9092 3.63696 14.5927C5.12687 15.968 7.39085 17.0833 10.4998 17.0833C12.2959 17.0833 13.814 16.711 15.0659 16.1268L17.4694 18.5303C17.7623 18.8232 18.2372 18.8232 18.5301 18.5303C18.823 18.2374 18.823 17.7626 18.5301 17.4697L3.53009 2.46967ZM13.9229 14.9838L12.2057 13.2666C11.7098 13.5729 11.125 13.75 10.4998 13.75C8.70483 13.75 7.24976 12.2949 7.24976 10.5C7.24976 9.87478 7.42685 9.28992 7.73312 8.79403L5.66715 6.72806C4.74179 7.3217 4.06395 8.04327 3.61101 8.72817C3.10202 9.49782 2.9165 10.1583 2.9165 10.5C2.9165 10.7551 3.02103 11.2066 3.31346 11.7681C3.5989 12.3161 4.04068 12.924 4.65438 13.4905C5.8728 14.6152 7.77549 15.5833 10.4998 15.5833C11.8353 15.5833 12.9706 15.3507 13.9229 14.9838ZM8.85086 9.91177C8.78535 10.0955 8.74976 10.2934 8.74976 10.5C8.74976 11.4665 9.53326 12.25 10.4998 12.25C10.7064 12.25 10.9043 12.2144 11.088 12.1489L8.85086 9.91177ZM10.4998 5.41665C9.64208 5.41665 8.86806 5.51261 8.17314 5.67765C7.77014 5.77337 7.36585 5.52426 7.27014 5.12126C7.17442 4.71826 7.42353 4.31397 7.82653 4.21825C8.63884 4.02532 9.52936 3.91665 10.4998 3.91665C13.6088 3.91665 15.8728 5.03191 17.3627 6.40721C18.1032 7.09072 18.651 7.83698 19.0166 8.53895C19.3752 9.22747 19.5832 9.92172 19.5832 10.5C19.5832 11.5532 18.8904 13.0785 17.6007 14.3644C17.3074 14.6569 16.8325 14.6562 16.5401 14.3629C16.2476 14.0695 16.2483 13.5947 16.5416 13.3022C17.6598 12.1873 18.0832 11.0216 18.0832 10.5C18.0832 10.2449 17.9786 9.79332 17.6862 9.23185C17.4008 8.68381 16.959 8.07591 16.3453 7.50942C15.1269 6.38472 13.2242 5.41665 10.4998 5.41665Z"
				fill={mainColor.iconGray}
			/>
		</svg>
	),
	Eye: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75 12C2.75 11.6616 2.88577 11.0954 3.24331 10.409C3.59385 9.73591 4.13459 8.9926 4.88371 8.3011C6.37297 6.9264 8.69232 5.75 12 5.75C15.3077 5.75 17.627 6.9264 19.1163 8.3011C19.8654 8.9926 20.4061 9.73591 20.7567 10.409C21.1142 11.0954 21.25 11.6616 21.25 12C21.25 12.3384 21.1142 12.9046 20.7567 13.591C20.4061 14.2641 19.8654 15.0074 19.1163 15.6989C17.627 17.0736 15.3077 18.25 12 18.25C8.69232 18.25 6.37297 17.0736 4.88371 15.6989C4.13459 15.0074 3.59385 14.2641 3.24331 13.591C2.88577 12.9046 2.75 12.3384 2.75 12ZM12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM9.7499 12C9.7499 10.7574 10.7573 9.75002 11.9999 9.75002C13.2425 9.75002 14.2499 10.7574 14.2499 12C14.2499 13.2427 13.2425 14.25 11.9999 14.25C10.7573 14.25 9.7499 13.2427 9.7499 12ZM11.9999 8.25002C9.92883 8.25002 8.2499 9.92896 8.2499 12C8.2499 14.0711 9.92883 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92896 14.071 8.25002 11.9999 8.25002Z"
				fill={mainColor.iconGray}
			/>
		</svg>
	),
	GoogleIcon: () => (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.26 12.273C24.26 11.4221 24.1837 10.6039 24.0418 9.81848H12.74V14.4603H19.1982C18.92 15.9603 18.0745 17.2312 16.8036 18.0821V21.0931H20.6818C22.9509 19.004 24.26 15.9276 24.26 12.273Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 23.9999C15.98 23.9999 18.6964 22.9254 20.6819 21.0926L16.8037 18.0817C15.7291 18.8017 14.3546 19.2272 12.74 19.2272C9.61456 19.2272 6.96909 17.1163 6.02546 14.2799H2.01636V17.389C3.99091 21.3108 8.0491 23.9999 12.74 23.9999Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.02546 14.2801C5.78546 13.5601 5.64909 12.791 5.64909 12.0001C5.64909 11.2092 5.78546 10.4401 6.02546 9.72006V6.61096H2.01636C1.20363 8.23097 0.73999 10.0637 0.73999 12.0001C0.73999 13.9364 1.20363 15.7692 2.01636 17.3892L6.02546 14.2801Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 4.77286C14.5018 4.77286 16.0837 5.37832 17.3273 6.56741L20.7691 3.12558C18.6909 1.18922 15.9746 0.00012207 12.74 0.00012207C8.0491 0.00012207 3.99091 2.68922 2.01636 6.61104L6.02546 9.72014C6.96909 6.88377 9.61455 4.77286 12.74 4.77286Z"
				fill="#EA4335"
			/>
		</svg>
	),
	Tick: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="20"
			viewBox="0 0 19 20"
			fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.946 1.14424C10.0147 0.358169 8.6523 0.35817 7.72098 1.14424L6.87282 1.86013C6.47698 2.19423 5.98715 2.39714 5.471 2.44079L4.36505 2.53432C3.15067 2.63702 2.18731 3.60038 2.08461 4.81475L1.99108 5.9207C1.94743 6.43685 1.74452 6.92669 1.41042 7.32254L0.694533 8.17069C-0.0915378 9.102 -0.0915366 10.4644 0.694533 11.3957L1.41042 12.2439C1.74452 12.6397 1.94743 13.1296 1.99108 13.6457L2.08461 14.7517C2.18731 15.9661 3.15067 16.9295 4.36505 17.0321L5.471 17.1256C5.98715 17.1693 6.47698 17.3722 6.87283 17.7063L7.72098 18.4222C8.6523 19.2082 10.0147 19.2082 10.946 18.4222L11.7942 17.7063C12.19 17.3722 12.6799 17.1693 13.196 17.1256L14.302 17.0321C15.5164 16.9295 16.4797 15.9661 16.5824 14.7517L16.6759 13.6457C16.7196 13.1296 16.9225 12.6397 17.2566 12.2439L17.9725 11.3957C18.7585 10.4644 18.7585 9.102 17.9725 8.17069L17.2566 7.32253C16.9225 6.92669 16.7196 6.43685 16.6759 5.9207L16.5824 4.81475C16.4797 3.60038 15.5164 2.63702 14.302 2.53432L13.196 2.44079C12.6799 2.39714 12.19 2.19423 11.7942 1.86013L10.946 1.14424ZM13.8795 8.07875C14.3189 7.63942 14.3189 6.9271 13.8795 6.48777C13.4402 6.04842 12.7279 6.04842 12.2885 6.48777L8.08403 10.6923L6.37953 8.98777C5.9402 8.54842 5.22788 8.54842 4.78855 8.98777C4.3492 9.4271 4.3492 10.1394 4.78855 10.5788L7.28855 13.0787C7.72788 13.5181 8.4402 13.5181 8.87953 13.0787L13.8795 8.07875Z"
				fill={mainColor.iconBlue}
			/>
		</svg>
	),
	UnCheckBox: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="17" height="17" rx="5.5" fill="white" />
			<rect x="1" y="1" width="17" height="17" rx="5.5" stroke="black" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6738 5.44714C14.9791 5.72703 14.9998 6.20146 14.7199 6.5068L8.3032 13.5068C8.16497 13.6576 7.97114 13.7454 7.76662 13.7498C7.56211 13.7543 7.36465 13.675 7.22 13.5303L4.30333 10.6137C4.01044 10.3208 4.01044 9.8459 4.30333 9.55301C4.59623 9.26011 5.0711 9.26011 5.36399 9.55301L7.72677 11.9158L13.6141 5.49321C13.894 5.18787 14.3685 5.16724 14.6738 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	Checked: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect
				x="0.5"
				y="0.5"
				width="18"
				height="18"
				rx="6"
				fill={mainColor.iconDarkBlue}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6737 5.44714C14.9791 5.72703 14.9997 6.20146 14.7198 6.5068L8.30315 13.5068C8.16492 13.6576 7.97109 13.7454 7.76658 13.7498C7.56206 13.7543 7.3646 13.675 7.21995 13.5303L4.30329 10.6137C4.01039 10.3208 4.01039 9.8459 4.30329 9.55301C4.59618 9.26011 5.07105 9.26011 5.36395 9.55301L7.72673 11.9158L13.6141 5.49321C13.894 5.18787 14.3684 5.16724 14.6737 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	List: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none">
			<path
				d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM6 15H18C18.55 15 19 14.55 19 14C19 13.45 18.55 13 18 13H6C5.45 13 5 13.45 5 14C5 14.55 5.45 15 6 15ZM6 9H18C18.55 9 19 8.55 19 8C19 7.45 18.55 7 18 7H6C5.45 7 5 7.45 5 8C5 8.55 5.45 9 6 9ZM5 2C5 2.55 5.45 3 6 3H18C18.55 3 19 2.55 19 2C19 1.45 18.55 1 18 1H6C5.45 1 5 1.45 5 2Z"
				fill="white"
			/>
		</svg>
	),
	Menu: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M21 18H3"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 14H3"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 10H3"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 6H3"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Notification: (color: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 21 22"
			fill="none">
			<path
				d="M7.02051 18C7.02051 18.625 7.09395 19.3497 7.74544 19.8021C8.39692 20.2546 9.19708 20.5 10.0205 20.5C10.8439 20.5 11.6441 20.2546 12.2956 19.8021C12.9471 19.3497 13.0205 18.625 13.0205 18"
				stroke={color || mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.51946 8.5C3.51946 4.91015 6.42961 2 10.0195 2V0C5.32504 0 1.51946 3.80558 1.51946 8.5V11.6074C1.51946 12.295 1.29644 12.964 0.883891 13.5141L0.620824 13.8648C0.336017 14.2446 -0.140295 14.9809 0.0394786 15.8658C0.32105 17.2517 1.53111 17.721 2.44274 17.8851C3.19781 18.021 4.07554 18.0109 4.74867 18.0032C4.89215 18.0015 5.0264 18 5.14812 18H10.0195V16H5.14812C4.97806 16 4.8095 16.0016 4.64317 16.0032C3.97644 16.0095 3.34551 16.0155 2.79707 15.9168C2.11669 15.7943 2.0277 15.6006 2.00052 15.4728C2.00112 15.4643 2.00461 15.4405 2.02161 15.3965C2.0539 15.3129 2.1174 15.2027 2.22082 15.0648L2.48389 14.7141C3.15609 13.8178 3.51946 12.7277 3.51946 11.6074V8.5ZM2.00048 15.4765C2.00044 15.4765 2.00039 15.4758 2.00044 15.4743L2.00048 15.4765Z"
				fill={color || mainColor.textMain}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5203 8.5C16.5203 4.91015 13.6093 2 10.0195 2V0C14.7139 0 18.5203 3.80558 18.5203 8.5V11.6074C18.5203 12.295 18.7433 12.964 19.1558 13.5141L19.4189 13.8648C19.7037 14.2446 20.18 14.9809 20.0002 15.8658C19.7187 17.2517 18.5086 17.721 17.597 17.8851C16.8419 18.021 15.9642 18.0109 15.291 18.0032C15.1476 18.0015 15.0133 18 14.8916 18H10.0195V16H14.8916C15.0617 16 15.2302 16.0016 15.3966 16.0032C16.0633 16.0095 16.6942 16.0155 17.2426 15.9168C17.923 15.7943 18.012 15.6006 18.0392 15.4728C18.0386 15.4643 18.0351 15.4405 18.0181 15.3965C17.9858 15.3129 17.9223 15.2027 17.8189 15.0648L17.5558 14.7141C16.8836 13.8178 16.5203 12.7277 16.5203 11.6074V8.5ZM18.0392 15.4765C18.0393 15.4765 18.0393 15.4758 18.0393 15.4743L18.0392 15.4765Z"
				fill={color || mainColor.textMain}
			/>
		</svg>
	),
	Dashboard: () => (
		<svg
			className="fill-current"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.10322 0.956299H2.53135C1.5751 0.956299 0.787598 1.7438 0.787598 2.70005V6.27192C0.787598 7.22817 1.5751 8.01567 2.53135 8.01567H6.10322C7.05947 8.01567 7.84697 7.22817 7.84697 6.27192V2.72817C7.8751 1.7438 7.0876 0.956299 6.10322 0.956299ZM6.60947 6.30005C6.60947 6.5813 6.38447 6.8063 6.10322 6.8063H2.53135C2.2501 6.8063 2.0251 6.5813 2.0251 6.30005V2.72817C2.0251 2.44692 2.2501 2.22192 2.53135 2.22192H6.10322C6.38447 2.22192 6.60947 2.44692 6.60947 2.72817V6.30005Z"
				fill=""
			/>
			<path
				d="M15.4689 0.956299H11.8971C10.9408 0.956299 10.1533 1.7438 10.1533 2.70005V6.27192C10.1533 7.22817 10.9408 8.01567 11.8971 8.01567H15.4689C16.4252 8.01567 17.2127 7.22817 17.2127 6.27192V2.72817C17.2127 1.7438 16.4252 0.956299 15.4689 0.956299ZM15.9752 6.30005C15.9752 6.5813 15.7502 6.8063 15.4689 6.8063H11.8971C11.6158 6.8063 11.3908 6.5813 11.3908 6.30005V2.72817C11.3908 2.44692 11.6158 2.22192 11.8971 2.22192H15.4689C15.7502 2.22192 15.9752 2.44692 15.9752 2.72817V6.30005Z"
				fill=""
			/>
			<path
				d="M6.10322 9.92822H2.53135C1.5751 9.92822 0.787598 10.7157 0.787598 11.672V15.2438C0.787598 16.2001 1.5751 16.9876 2.53135 16.9876H6.10322C7.05947 16.9876 7.84697 16.2001 7.84697 15.2438V11.7001C7.8751 10.7157 7.0876 9.92822 6.10322 9.92822ZM6.60947 15.272C6.60947 15.5532 6.38447 15.7782 6.10322 15.7782H2.53135C2.2501 15.7782 2.0251 15.5532 2.0251 15.272V11.7001C2.0251 11.4188 2.2501 11.1938 2.53135 11.1938H6.10322C6.38447 11.1938 6.60947 11.4188 6.60947 11.7001V15.272Z"
				fill=""
			/>
			<path
				d="M15.4689 9.92822H11.8971C10.9408 9.92822 10.1533 10.7157 10.1533 11.672V15.2438C10.1533 16.2001 10.9408 16.9876 11.8971 16.9876H15.4689C16.4252 16.9876 17.2127 16.2001 17.2127 15.2438V11.7001C17.2127 10.7157 16.4252 9.92822 15.4689 9.92822ZM15.9752 15.272C15.9752 15.5532 15.7502 15.7782 15.4689 15.7782H11.8971C11.6158 15.7782 11.3908 15.5532 11.3908 15.272V11.7001C11.3908 11.4188 11.6158 11.1938 11.8971 11.1938H15.4689C15.7502 11.1938 15.9752 11.4188 15.9752 11.7001V15.272Z"
				fill=""
			/>
		</svg>
	),
	Home: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none">
			<path
				d="M1 19V8.67412C1 8.04041 1.30033 7.4442 1.80954 7.067L8.80955 1.88182C9.51674 1.35797 10.4833 1.35797 11.1905 1.88182L18.1905 7.067C18.6997 7.4442 19 8.04041 19 8.67412V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19Z"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
			/>
			<path
				d="M6.5 21V14C6.5 13.1716 7.17157 12.5 8 12.5H12C12.8284 12.5 13.5 13.1716 13.5 14V21"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Setting: (color: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M13.7462 10.9472C13.7462 12.4812 12.5188 13.7499 11 13.7499C9.48124 13.7499 8.24512 12.4812 8.24512 10.9472C8.24512 9.41318 9.48124 8.24988 11 8.24988C12.5188 8.24988 13.7462 9.41318 13.7462 10.9472Z"
				stroke={color || mainColor.textMain}
				strokeWidth="2"
			/>
			<path
				d="M13.2127 3.04115L12.5749 1.5962C12.4149 1.23381 12.0562 1 11.66 1H10.3151C9.91901 1 9.56023 1.23381 9.40028 1.5962L8.7625 3.04115C8.14903 4.43103 6.70895 5.26643 5.19788 5.10901L3.39332 4.92102C3.00234 4.88029 2.62377 5.07239 2.42579 5.41199L1.83283 6.42909C1.63099 6.77531 1.65534 7.2086 1.89471 7.53002L2.92975 8.91989C3.84991 10.1555 3.85394 11.8475 2.93967 13.0875L1.89016 14.5108C1.65335 14.832 1.63015 15.2632 1.83113 15.608L2.42295 16.6231C2.62238 16.9652 3.00486 17.1573 3.39835 17.1132L5.17891 16.9134C6.69885 16.7429 8.15349 17.5791 8.77109 18.9783L9.40028 20.4038C9.56023 20.7662 9.91901 21 10.3151 21H11.66C12.0562 21 12.4149 20.7662 12.5749 20.4038L13.2041 18.9783C13.8217 17.5791 15.2763 16.7429 16.7963 16.9134L18.5829 17.1139C18.9733 17.1577 19.3531 16.9688 19.5539 16.6312L20.1632 15.6064C20.3676 15.2627 20.3476 14.8304 20.1124 14.5071L19.0664 13.069C18.1701 11.8368 18.174 10.1661 19.0762 8.93811L20.1079 7.53379C20.3456 7.2102 20.3667 6.77581 20.1615 6.43068L19.5511 5.40395C19.3518 5.06875 18.9758 4.87998 18.5879 4.92039L16.7773 5.10901C15.2662 5.26643 13.8261 4.43103 13.2127 3.04115Z"
				stroke={color || mainColor.textMain}
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	),
	Help: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke={mainColor.textMain}
				strokeWidth="2"
			/>
			<path
				d="M8.5 7.71806C8.63486 7.36247 8.83863 7.04373 9.09392 6.78019C9.56143 6.29757 10.2017 6 10.908 6C12.3395 6 13.5 7.22255 13.5 8.73065C13.5 10.0598 12.5986 11.1671 11.4047 11.4112C11.1342 11.4665 10.908 11.6852 10.908 11.9613V13"
				stroke={mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M11 17.25C11.5523 17.25 12 16.8023 12 16.25C12 15.6977 11.5523 15.25 11 15.25C10.4477 15.25 10 15.6977 10 16.25C10 16.8023 10.4477 17.25 11 17.25Z"
				fill={mainColor.textMain}
			/>
		</svg>
	),
	Logout: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M16 17L21 12L16 7"
				stroke={mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 12H9"
				stroke={mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
				stroke={mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Template: (color?: string) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4932_561)">
				<path
					d="M16.5167 1.66675H3.48341C2.4801 1.66675 1.66675 2.4801 1.66675 3.48341V16.5167C1.66675 17.5201 2.4801 18.3334 3.48341 18.3334H16.5167C17.5201 18.3334 18.3334 17.5201 18.3334 16.5167V3.48341C18.3334 2.4801 17.5201 1.66675 16.5167 1.66675Z"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.83325 1.66675V18.3334"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.1667 1.66675V18.3334"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.66675 10H18.3334"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.66675 5.83325H5.83341"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.66675 14.1667H5.83341"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.1667 14.1667H18.3334"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.1667 5.83325H18.3334"
					stroke={color ?? mainColor.textMain}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4932_561">
					<rect width="20" height="20" fill={color ?? mainColor.textMain} />
				</clipPath>
			</defs>
		</svg>
	),
	Transaction: (color?: string) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 9.5H3.41886C4.06451 9.5 4.63772 9.08685 4.84189 8.47434L5.73656 5.79032C6.01829 4.94514 7.18124 4.86249 7.57967 5.65934L9.5 9.5L11.6056 13.7111C11.9741 14.4482 13.0259 14.4482 13.3944 13.7111L15.0854 10.3292C15.3395 9.821 15.8589 9.5 16.4271 9.5H19"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Pricing: (color?: string) => (
		<svg
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.66667 8.50791V2.14098C9.66667 1.68217 9.10039 1.46562 8.79425 1.80736L1.24679 10.2324C0.958311 10.5545 1.18687 11.0661 1.61921 11.0661H6.33333"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M9.66671 8.50781H14.4106C14.8381 8.50781 15.0685 9.00966 14.7897 9.33383L7.21247 18.1445C6.91005 18.4962 6.33337 18.2823 6.33337 17.8185V11.066"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	SoudChart: () => (
		<svg
			width="34"
			height="36"
			viewBox="0 0 34 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5036_6913)">
				<rect y="16" width="2" height="4" fill={mainColor.secondary} />
				<rect x="4" y="12" width="2" height="12" fill={mainColor.secondary} />
				<rect x="8" y="9" width="2" height="18" fill={mainColor.secondary} />
				<rect x="12" y="14" width="2" height="8" fill={mainColor.secondary} />
				<rect x="16" y="14" width="2" height="8" fill={mainColor.secondary} />
				<rect x="20" y="11" width="2" height="14" fill={mainColor.secondary} />
				<rect x="24" y="14" width="2" height="8" fill={mainColor.secondary} />
				<rect x="28" y="14" width="2" height="8" fill={mainColor.secondary} />
				<rect x="32" y="14" width="2" height="8" fill={mainColor.secondary} />
			</g>
			<defs>
				<clipPath id="clip0_5036_6913">
					<rect width="34" height="36" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	Play: () => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.16699 2.5L15.8337 10L4.16699 17.5V2.5Z"
				fill="white"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Stop: () => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M15.0003 3.33337H11.667V16.6667H15.0003V3.33337Z" fill="white" />
			<path d="M8.33333 3.33337H5V16.6667H8.33333V3.33337Z" fill="white" />
		</svg>
	),
	// VoicePreview: () => (
	//   <svg width="91" height="90" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
	//     <path d="M39.7915 10.4483C39.4006 10.4483 39.084 10.1316 39.084 9.74074V4.95262C39.084 3.00223 37.4968 1.41503 35.5464 1.41503H30.7583C30.3674 1.41503 30.0508 1.09842 30.0508 0.707517C30.0508 0.316614 30.3674 0 30.7583 0H35.5464C38.2774 0 40.499 2.2216 40.499 4.95262V9.74074C40.499 10.1316 40.1824 10.4483 39.7915 10.4483Z" fill="#7340D8" fillOpacity="0.5" />
	//     <path d="M1.20752 10.4483C0.816614 10.4483 0.5 10.1316 0.5 9.74074V4.95262C0.5 2.2216 2.7216 0 5.45262 0H10.2407C10.6316 0 10.9483 0.316614 10.9483 0.707517C10.9483 1.09842 10.6316 1.41503 10.2407 1.41503H5.45262C3.50223 1.41503 1.91503 3.00223 1.91503 4.95262V9.74074C1.91503 10.1316 1.59842 10.4483 1.20752 10.4483Z" fill="#7340D8" fillOpacity="0.5" />
	//     <path d="M10.2407 40H5.45262C2.7216 40 0.5 37.7784 0.5 35.0474V30.2593C0.5 29.8684 0.816614 29.5518 1.20752 29.5518C1.59842 29.5518 1.91503 29.8684 1.91503 30.2593V35.0474C1.91503 36.9978 3.50223 38.585 5.45262 38.585H10.2407C10.6316 38.585 10.9483 38.9016 10.9483 39.2925C10.9483 39.6834 10.6316 40 10.2407 40Z" fill="#7340D8" fillOpacity="0.5" />
	//     <path d="M35.5464 40H30.7583C30.3674 40 30.0508 39.6834 30.0508 39.2925C30.0508 38.9016 30.3674 38.585 30.7583 38.585H35.5464C37.4968 38.585 39.084 36.9978 39.084 35.0474V30.2593C39.084 29.8684 39.4006 29.5518 39.7915 29.5518C40.1824 29.5518 40.499 29.8684 40.499 30.2593V35.0474C40.499 37.7784 38.2774 40 35.5464 40Z" fill="#7340D8" fillOpacity="0.5" />
	//     <g clipPath="url(#clip0_5036_7212)">
	//       <path d="M15.665 31.8181C15.6321 31.7993 15.5998 31.7795 15.5659 31.7623C15.2826 31.6172 15.1417 31.3355 15.2111 31.0522C15.2831 30.7596 15.5236 30.5702 15.8408 30.5691C16.7146 30.5665 17.5885 30.5681 18.4618 30.5681C18.9303 30.5681 19.3982 30.5681 19.8782 30.5681V28.7297C19.6596 28.6952 19.4358 28.666 19.2146 28.6238C16.1997 28.052 13.9079 25.7644 13.3424 22.7647C13.2741 22.4016 13.4739 22.0828 13.8161 22.0098C14.1698 21.9341 14.4771 22.1512 14.5705 22.5163C14.6754 22.9243 14.7599 23.3448 14.9252 23.7293C15.8398 25.8536 17.4502 27.125 19.7462 27.4369C22.9864 27.8778 25.8875 25.6694 26.4624 22.5377C26.5276 22.1825 26.8151 21.9592 27.1375 21.9947C27.4588 22.0301 27.715 22.2946 27.7056 22.6092C27.7009 22.7699 27.666 22.9327 27.6237 23.0881C26.7911 26.139 24.8259 27.9951 21.7229 28.6452C21.6274 28.665 21.5298 28.6759 21.4328 28.6885C21.3431 28.7005 21.2533 28.7088 21.1532 28.7203V30.5671C21.2189 30.5671 21.2873 30.5671 21.3561 30.5671C22.6343 30.5671 23.9119 30.566 25.19 30.5681C25.5109 30.5686 25.7618 30.7726 25.8254 31.0705C25.8854 31.3532 25.7357 31.6313 25.4488 31.7701C25.4206 31.7836 25.3945 31.8014 25.3674 31.817H15.665V31.8181Z" fill="#7340D8" fillOpacity="0.5" />
	//       <path d="M10.4995 16.7434C10.6174 16.48 10.7849 16.2797 11.1021 16.2708C11.4475 16.2609 11.7422 16.517 11.7474 16.8619C11.7579 17.5108 11.7558 18.1598 11.7479 18.8093C11.7448 19.0916 11.5278 19.3352 11.2591 19.3926C10.9842 19.4515 10.7056 19.3195 10.57 19.0665C10.546 19.0217 10.5225 18.9763 10.499 18.9309C10.499 18.2021 10.499 17.4728 10.499 16.744L10.4995 16.7434Z" fill="#7340D8" fillOpacity="0.5" />
	//       <path d="M30.5332 18.9303C30.4116 19.1901 30.2463 19.393 29.9286 19.4019C29.5832 19.4113 29.29 19.1536 29.2848 18.8087C29.2754 18.1597 29.277 17.5102 29.2848 16.8612C29.2879 16.5853 29.494 16.3479 29.7554 16.2837C30.0298 16.2164 30.3115 16.3406 30.4529 16.591C30.481 16.6411 30.5066 16.6927 30.5337 16.7433V18.9303H30.5332Z" fill="#7340D8" fillOpacity="0.5" />
	//       <path d="M24.868 18.8229C24.868 19.7468 24.8868 20.6707 24.8644 21.5941C24.8106 23.7972 23.0484 25.6586 20.9037 25.801C18.5916 25.9549 16.6024 24.4316 16.2226 22.2087C16.1777 21.9473 16.1631 21.6781 16.1626 21.4126C16.1574 19.6821 16.1542 17.9517 16.1615 16.2207C16.1704 14.1068 17.545 12.3894 19.5885 11.923C22.0222 11.368 24.4976 13.0734 24.8184 15.5472C24.8831 16.0475 24.8633 16.5593 24.8701 17.0659C24.8779 17.6512 24.8717 18.2371 24.8717 18.8224L24.868 18.8229Z" fill="#7340D8" fillOpacity="0.5" />
	//       <path d="M14.5737 17.8451C14.5737 18.4236 14.5748 19.0027 14.5737 19.5813C14.5727 19.984 14.3202 20.2595 13.9561 20.2621C13.5909 20.2642 13.3238 19.9871 13.3232 19.5922C13.3206 18.4153 13.3206 17.2384 13.3232 16.0609C13.3243 15.7265 13.5397 15.4651 13.8371 15.4171C14.1522 15.366 14.4449 15.5377 14.5409 15.8402C14.5675 15.9248 14.5722 16.0192 14.5722 16.1089C14.5748 16.6875 14.5737 17.2665 14.5737 17.8451Z" fill="#7340D8" fillOpacity="0.5" />
	//       <path d="M26.4597 17.8263C26.4597 17.2478 26.4587 16.6687 26.4597 16.0901C26.4608 15.6843 26.7096 15.413 27.0774 15.4109C27.4457 15.4088 27.7097 15.6822 27.7102 16.0802C27.7128 17.2572 27.7133 18.4341 27.7102 19.6115C27.7092 19.9527 27.4827 20.2178 27.176 20.2579C26.8682 20.2981 26.5813 20.1244 26.4915 19.8317C26.4655 19.7467 26.4613 19.6528 26.4608 19.563C26.4581 18.9845 26.4592 18.4054 26.4592 17.8268L26.4597 17.8263Z" fill="#7340D8" fillOpacity="0.5" />
	//     </g>
	//     <defs>
	//       <clipPath id="clip0_5036_7212">
	//         <rect width="91" height="90" fill="white" transform="translate(10.5 11.8181)" />
	//       </clipPath>
	//     </defs>
	//   </svg>

	// ),
	VoicePreview: (width?: string, height?: string) => (
		<svg
			width={width || "91px"}
			height={height || "90px"}
			viewBox="0 0 41 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M39.7915 10.4483C39.4006 10.4483 39.084 10.1316 39.084 9.74074V4.95262C39.084 3.00223 37.4968 1.41503 35.5464 1.41503H30.7583C30.3674 1.41503 30.0508 1.09842 30.0508 0.707517C30.0508 0.316614 30.3674 0 30.7583 0H35.5464C38.2774 0 40.499 2.2216 40.499 4.95262V9.74074C40.499 10.1316 40.1824 10.4483 39.7915 10.4483Z"
				fill={mainColor.secondary}
				fillOpacity="0.5"
			/>
			<path
				d="M1.20752 10.4483C0.816614 10.4483 0.5 10.1316 0.5 9.74074V4.95262C0.5 2.2216 2.7216 0 5.45262 0H10.2407C10.6316 0 10.9483 0.316614 10.9483 0.707517C10.9483 1.09842 10.6316 1.41503 10.2407 1.41503H5.45262C3.50223 1.41503 1.91503 3.00223 1.91503 4.95262V9.74074C1.91503 10.1316 1.59842 10.4483 1.20752 10.4483Z"
				fill={mainColor.secondary}
				fillOpacity="0.5"
			/>
			<path
				d="M10.2407 40H5.45262C2.7216 40 0.5 37.7784 0.5 35.0474V30.2593C0.5 29.8684 0.816614 29.5518 1.20752 29.5518C1.59842 29.5518 1.91503 29.8684 1.91503 30.2593V35.0474C1.91503 36.9978 3.50223 38.585 5.45262 38.585H10.2407C10.6316 38.585 10.9483 38.9016 10.9483 39.2925C10.9483 39.6834 10.6316 40 10.2407 40Z"
				fill={mainColor.secondary}
				fillOpacity="0.5"
			/>
			<path
				d="M35.5464 40H30.7583C30.3674 40 30.0508 39.6834 30.0508 39.2925C30.0508 38.9016 30.3674 38.585 30.7583 38.585H35.5464C37.4968 38.585 39.084 36.9978 39.084 35.0474V30.2593C39.084 29.8684 39.4006 29.5518 39.7915 29.5518C40.1824 29.5518 40.499 29.8684 40.499 30.2593V35.0474C40.499 37.7784 38.2774 40 35.5464 40Z"
				fill={mainColor.secondary}
				fillOpacity="0.5"
			/>
			<g clipPath="url(#clip0_5036_7212)">
				<path
					d="M15.665 31.8181C15.6321 31.7993 15.5998 31.7795 15.5659 31.7623C15.2826 31.6172 15.1417 31.3355 15.2111 31.0522C15.2831 30.7596 15.5236 30.5702 15.8408 30.5691C16.7146 30.5665 17.5885 30.5681 18.4618 30.5681C18.9303 30.5681 19.3982 30.5681 19.8782 30.5681V28.7297C19.6596 28.6952 19.4358 28.666 19.2146 28.6238C16.1997 28.052 13.9079 25.7644 13.3424 22.7647C13.2741 22.4016 13.4739 22.0828 13.8161 22.0098C14.1698 21.9341 14.4771 22.1512 14.5705 22.5163C14.6754 22.9243 14.7599 23.3448 14.9252 23.7293C15.8398 25.8536 17.4502 27.125 19.7462 27.4369C22.9864 27.8778 25.8875 25.6694 26.4624 22.5377C26.5276 22.1825 26.8151 21.9592 27.1375 21.9947C27.4588 22.0301 27.715 22.2946 27.7056 22.6092C27.7009 22.7699 27.666 22.9327 27.6237 23.0881C26.7911 26.139 24.8259 27.9951 21.7229 28.6452C21.6274 28.665 21.5298 28.6759 21.4328 28.6885C21.3431 28.7005 21.2533 28.7088 21.1532 28.7203V30.5671C21.2189 30.5671 21.2873 30.5671 21.3561 30.5671C22.6343 30.5671 23.9119 30.566 25.19 30.5681C25.5109 30.5686 25.7618 30.7726 25.8254 31.0705C25.8854 31.3532 25.7357 31.6313 25.4488 31.7701C25.4206 31.7836 25.3945 31.8014 25.3674 31.817H15.665V31.8181Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
				<path
					d="M10.4995 16.7434C10.6174 16.48 10.7849 16.2797 11.1021 16.2708C11.4475 16.2609 11.7422 16.517 11.7474 16.8619C11.7579 17.5108 11.7558 18.1598 11.7479 18.8093C11.7448 19.0916 11.5278 19.3352 11.2591 19.3926C10.9842 19.4515 10.7056 19.3195 10.57 19.0665C10.546 19.0217 10.5225 18.9763 10.499 18.9309C10.499 18.2021 10.499 17.4728 10.499 16.744L10.4995 16.7434Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
				<path
					d="M30.5332 18.9303C30.4116 19.1901 30.2463 19.393 29.9286 19.4019C29.5832 19.4113 29.29 19.1536 29.2848 18.8087C29.2754 18.1597 29.277 17.5102 29.2848 16.8612C29.2879 16.5853 29.494 16.3479 29.7554 16.2837C30.0298 16.2164 30.3115 16.3406 30.4529 16.591C30.481 16.6411 30.5066 16.6927 30.5337 16.7433V18.9303H30.5332Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
				<path
					d="M24.868 18.8229C24.868 19.7468 24.8868 20.6707 24.8644 21.5941C24.8106 23.7972 23.0484 25.6586 20.9037 25.801C18.5916 25.9549 16.6024 24.4316 16.2226 22.2087C16.1777 21.9473 16.1631 21.6781 16.1626 21.4126C16.1574 19.6821 16.1542 17.9517 16.1615 16.2207C16.1704 14.1068 17.545 12.3894 19.5885 11.923C22.0222 11.368 24.4976 13.0734 24.8184 15.5472C24.8831 16.0475 24.8633 16.5593 24.8701 17.0659C24.8779 17.6512 24.8717 18.2371 24.8717 18.8224L24.868 18.8229Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
				<path
					d="M14.5737 17.8451C14.5737 18.4236 14.5748 19.0027 14.5737 19.5813C14.5727 19.984 14.3202 20.2595 13.9561 20.2621C13.5909 20.2642 13.3238 19.9871 13.3232 19.5922C13.3206 18.4153 13.3206 17.2384 13.3232 16.0609C13.3243 15.7265 13.5397 15.4651 13.8371 15.4171C14.1522 15.366 14.4449 15.5377 14.5409 15.8402C14.5675 15.9248 14.5722 16.0192 14.5722 16.1089C14.5748 16.6875 14.5737 17.2665 14.5737 17.8451Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
				<path
					d="M26.4597 17.8263C26.4597 17.2478 26.4587 16.6687 26.4597 16.0901C26.4608 15.6843 26.7096 15.413 27.0774 15.4109C27.4457 15.4088 27.7097 15.6822 27.7102 16.0802C27.7128 17.2572 27.7133 18.4341 27.7102 19.6115C27.7092 19.9527 27.4827 20.2178 27.176 20.2579C26.8682 20.2981 26.5813 20.1244 26.4915 19.8317C26.4655 19.7467 26.4613 19.6528 26.4608 19.563C26.4581 18.9845 26.4592 18.4054 26.4592 17.8268L26.4597 17.8263Z"
					fill={mainColor.secondary}
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5036_7212">
					<rect
						width="91"
						height="90"
						fill="white"
						transform="translate(10.5 11.8181)"
					/>
				</clipPath>
			</defs>
		</svg>
	),
	// AvatarPreview: () => (
	//   <svg width="101" height="100" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
	//     <g opacity="0.5">
	//       <path d="M39.7915 10.4483C39.4006 10.4483 39.084 10.1316 39.084 9.74074V4.95262C39.084 3.00223 37.4968 1.41503 35.5464 1.41503H30.7583C30.3674 1.41503 30.0508 1.09842 30.0508 0.707517C30.0508 0.316614 30.3674 0 30.7583 0H35.5464C38.2774 0 40.499 2.2216 40.499 4.95262V9.74074C40.499 10.1316 40.1824 10.4483 39.7915 10.4483Z" fill="#7340D8" />
	//       <path d="M1.20752 10.4483C0.816614 10.4483 0.5 10.1316 0.5 9.74074V4.95262C0.5 2.2216 2.7216 0 5.45262 0H10.2407C10.6316 0 10.9483 0.316614 10.9483 0.707517C10.9483 1.09842 10.6316 1.41503 10.2407 1.41503H5.45262C3.50223 1.41503 1.91503 3.00223 1.91503 4.95262V9.74074C1.91503 10.1316 1.59842 10.4483 1.20752 10.4483Z" fill="#7340D8" />
	//       <path d="M10.2407 40H5.45262C2.7216 40 0.5 37.7784 0.5 35.0474V30.2593C0.5 29.8684 0.816614 29.5518 1.20752 29.5518C1.59842 29.5518 1.91503 29.8684 1.91503 30.2593V35.0474C1.91503 36.9978 3.50223 38.585 5.45262 38.585H10.2407C10.6316 38.585 10.9483 38.9016 10.9483 39.2925C10.9483 39.6834 10.6316 40 10.2407 40Z" fill="#7340D8" />
	//       <path d="M35.5464 40H30.7583C30.3674 40 30.0508 39.6834 30.0508 39.2925C30.0508 38.9016 30.3674 38.585 30.7583 38.585H35.5464C37.4968 38.585 39.084 36.9978 39.084 35.0474V30.2593C39.084 29.8684 39.4006 29.5518 39.7915 29.5518C40.1824 29.5518 40.499 29.8684 40.499 30.2593V35.0474C40.499 37.7784 38.2774 40 35.5464 40Z" fill="#7340D8" />
	//       <path d="M20.5 30C26.0228 30 30.5 25.5228 30.5 20C30.5 14.4772 26.0228 10 20.5 10C14.9772 10 10.5 14.4772 10.5 20C10.5 25.5228 14.9772 30 20.5 30Z" fill="#7340D8" stroke="#7340D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	//       <path d="M18.5 16L24.5 20L18.5 24V16Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	//     </g>
	//   </svg>

	// ),
	AvatarPreview: (width?: string, height?: string) => (
		<svg
			width={width || "101px"}
			height={height || "100px"}
			viewBox="0 0 41 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.5">
				<path
					d="M39.7915 10.4483C39.4006 10.4483 39.084 10.1316 39.084 9.74074V4.95262C39.084 3.00223 37.4968 1.41503 35.5464 1.41503H30.7583C30.3674 1.41503 30.0508 1.09842 30.0508 0.707517C30.0508 0.316614 30.3674 0 30.7583 0H35.5464C38.2774 0 40.499 2.2216 40.499 4.95262V9.74074C40.499 10.1316 40.1824 10.4483 39.7915 10.4483Z"
					fill={mainColor.secondary}
				/>
				<path
					d="M1.20752 10.4483C0.816614 10.4483 0.5 10.1316 0.5 9.74074V4.95262C0.5 2.2216 2.7216 0 5.45262 0H10.2407C10.6316 0 10.9483 0.316614 10.9483 0.707517C10.9483 1.09842 10.6316 1.41503 10.2407 1.41503H5.45262C3.50223 1.41503 1.91503 3.00223 1.91503 4.95262V9.74074C1.91503 10.1316 1.59842 10.4483 1.20752 10.4483Z"
					fill={mainColor.secondary}
				/>
				<path
					d="M10.2407 40H5.45262C2.7216 40 0.5 37.7784 0.5 35.0474V30.2593C0.5 29.8684 0.816614 29.5518 1.20752 29.5518C1.59842 29.5518 1.91503 29.8684 1.91503 30.2593V35.0474C1.91503 36.9978 3.50223 38.585 5.45262 38.585H10.2407C10.6316 38.585 10.9483 38.9016 10.9483 39.2925C10.9483 39.6834 10.6316 40 10.2407 40Z"
					fill={mainColor.secondary}
				/>
				<path
					d="M35.5464 40H30.7583C30.3674 40 30.0508 39.6834 30.0508 39.2925C30.0508 38.9016 30.3674 38.585 30.7583 38.585H35.5464C37.4968 38.585 39.084 36.9978 39.084 35.0474V30.2593C39.084 29.8684 39.4006 29.5518 39.7915 29.5518C40.1824 29.5518 40.499 29.8684 40.499 30.2593V35.0474C40.499 37.7784 38.2774 40 35.5464 40Z"
					fill={mainColor.secondary}
				/>
				<path
					d="M20.5 30C26.0228 30 30.5 25.5228 30.5 20C30.5 14.4772 26.0228 10 20.5 10C14.9772 10 10.5 14.4772 10.5 20C10.5 25.5228 14.9772 30 20.5 30Z"
					fill={mainColor.secondary}
					stroke={mainColor.secondary}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M18.5 16L24.5 20L18.5 24V16Z"
					fill="white"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	),
	Voice: (color?: string) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.31592 9.56758V4.56038C7.31592 3.07728 8.5182 1.875 10.0013 1.875C11.4844 1.875 12.6867 3.07728 12.6867 4.56038V9.56758C12.6867 11.0507 11.4844 12.253 10.0013 12.253C8.5182 12.253 7.31592 11.0507 7.31592 9.56758Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M7.02539 18.125H12.8936"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M10.0012 15.0952L10.0012 17.574"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M4.6958 11.2344C5.47584 13.4105 7.55675 14.9673 10.0013 14.9673C12.4458 14.9673 14.5267 13.4105 15.3067 11.2344"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Plus: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	PlusWhite: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Upload: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<g clipPath="url(#clip0_4376_1330)">
				<path
					d="M16 16L12 12L8 16"
					stroke={color || mainColor.secondary}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 12V21"
					stroke={color || mainColor.secondary}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M20.3895 18.3895C21.3648 17.8578 22.1353 17.0164 22.5793 15.9981C23.0234 14.9799 23.1157 13.8427 22.8417 12.7662C22.5677 11.6896 21.943 10.735 21.0661 10.0529C20.1893 9.37088 19.1103 9.00023 17.9995 8.9995H16.7395C16.4368 7.82874 15.8726 6.74183 15.0894 5.82049C14.3062 4.89915 13.3243 4.16735 12.2176 3.6801C11.1108 3.19286 9.90802 2.96285 8.69959 3.00738C7.49116 3.0519 6.30854 3.3698 5.24065 3.93716C4.17276 4.50453 3.24738 5.3066 2.53409 6.28308C1.8208 7.25956 1.33816 8.38503 1.12245 9.57489C0.906742 10.7647 0.963577 11.988 1.28869 13.1528C1.61379 14.3175 2.19871 15.3934 2.99947 16.2995"
					stroke={color || mainColor.secondary}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16 16L12 12L8 16"
					stroke={color || mainColor.secondary}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4376_1330">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	Download: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 -2 30 30"
			version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Icon-Set"
					transform="translate(-101.000000, -726.000000)"
					fill={mainColor.primary}>
					<path
						d="M130,726 L102,726 C101.448,726 101,726.448 101,727 L101,735 C101,735.553 101.448,736 102,736 C102.552,736 103,735.553 103,735 L103,728 L129,728 L129,735 C129,735.553 129.448,736 130,736 C130.552,736 131,735.553 131,735 L131,727 C131,726.447 130.552,726 130,726 L130,726 Z M120.267,744.3 L117,748.26 L117,734.002 C117,733.449 116.552,733 116,733 C115.448,733 115,733.449 115,734.002 L115,748.298 L111.702,744.3 C111.31,743.905 110.674,743.905 110.282,744.3 C109.89,744.693 109.89,745.332 110.282,745.726 L115.224,751.717 C115.433,751.927 115.71,752.017 115.984,752.002 C116.258,752.017 116.536,751.927 116.745,751.717 L121.687,745.726 C122.079,745.332 122.079,744.693 121.687,744.3 C121.295,743.905 120.659,743.905 120.267,744.3 L120.267,744.3 Z"
						id="download"></path>
				</g>
			</g>
		</svg>
	),
	Edit: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 21 20"
			fill="none">
			<path
				d="M10.5 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V9.5"
				stroke={mainColor.textMain}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M16.6888 6.85937L10.3566 13.146C10.1225 13.3784 9.83456 13.5493 9.51841 13.6434L5.76128 14.7625C5.57411 14.8182 5.39816 14.6474 5.4483 14.4587L6.36383 11.0126C6.45307 10.6767 6.6283 10.3698 6.87226 10.1223L13.3642 3.53472M16.6888 6.85937L18.5055 5.04267C19.2866 4.26162 19.2866 2.99529 18.5055 2.21424L18.0093 1.71802C17.2283 0.93697 15.9619 0.936969 15.1809 1.71802L13.3642 3.53472M16.6888 6.85937L13.3642 3.53472"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
			/>
		</svg>
	),
	Delete: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M3.5 4L3.5 19C3.5 20.1046 4.39543 21 5.5 21H16.5C17.6046 21 18.5 20.1046 18.5 19V4"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
			/>
			<path
				d="M9 10V15"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13 10V15"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1.5 4H20.5"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M6 4L6.22147 2.6712C6.3822 1.70683 7.21658 1 8.19425 1H11H13.8057C14.7834 1 15.6178 1.70683 15.7785 2.6712L16 4"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Back: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none">
			<path
				d="M19 9L1 9M1 9L8 1.5M1 9L8 16.5"
				stroke={mainColor.secondary}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Mic: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="36"
			viewBox="0 0 25 36"
			fill="none">
			<path
				d="M7.13184 17.1352L7.13184 7.12076C7.13184 4.15457 9.53641 1.75 12.5026 1.75C15.4688 1.75 17.8733 4.15457 17.8733 7.12076V17.1352C17.8733 20.1013 15.4688 22.5059 12.5026 22.5059C9.53641 22.5059 7.13184 20.1013 7.13184 17.1352Z"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M6.55078 34.25H18.2871"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M12.502 28.1906L12.502 33.1483"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1.8916 20.4686C3.45169 24.8209 7.61351 27.9343 12.5025 27.9343C17.3915 27.9343 21.5533 24.8209 23.1134 20.4686"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Reload: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M6.25 7.08301H1.66667V2.49967"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.69582 6.32293C4.72656 2.28884 9.64308 0.664796 13.6772 2.69554C17.7113 4.72628 19.3353 9.6428 17.3046 13.6769C15.2738 17.711 10.3573 19.335 6.32321 17.3043C4.33244 16.3022 2.92859 14.5972 2.26124 12.6424"
				stroke={mainColor.iconBlack}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Character: (color?: string) => (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.7499 5.41659C13.7499 7.60272 11.9777 9.37492 9.79159 9.37492C7.60549 9.37492 5.83325 7.60272 5.83325 5.41659C5.83325 3.23045 7.60549 1.45825 9.79159 1.45825C11.9777 1.45825 13.7499 3.23045 13.7499 5.41659Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M17.5 18.3332V17.6665C17.5 14.3528 14.8137 11.6665 11.5 11.6665H8.5C5.18629 11.6665 2.5 14.3528 2.5 17.6665V18.3332"
				stroke={color ?? mainColor.textMain}
				strokeWidth="2"
			/>
		</svg>
	),
	Complete: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none">
			<rect x="0.5" width="24" height="24" rx="12" fill={mainColor.success} />
			<path
				d="M6.5 11.6591L10.0405 15.0789C10.428 15.4533 11.0425 15.4533 11.43 15.0789L18.5 8.25"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	MoreVert: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z"
				stroke={mainColor.iconGray}
				strokeWidth="2"
			/>
			<path
				d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
				stroke={mainColor.iconGray}
				strokeWidth="2"
			/>
			<path
				d="M12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1046 10.8954 22 12 22Z"
				stroke={mainColor.iconGray}
				strokeWidth="2"
			/>
		</svg>
	),
	Search: () => (
		<svg
			className="w-4 h-4 text-gray-500 dark:text-gray-400"
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
			/>
		</svg>
	),
	Spinner: (color?: string) => (
		<svg
			aria-hidden="true"
			role="status"
			className="inline w-4 h-4 me-3 text-white animate-spin"
			viewBox="0 0 100 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
				fill={color || mainColor.primary}
			/>
			<path
				d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
				fill="currentColor"
			/>
		</svg>
	),
	CircleLoading: (color?: string) => (
		<svg
			aria-hidden="true"
			className="inline w-12 h-12 text-gray-200 animate-spin"
			viewBox="0 0 100 101"
			fill={color || mainColor.secondary}
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
				fill="currentColor"
			/>
			<path
				d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
				fill="currentFill"
			/>
		</svg>
	),
	CreditPlus: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="w-5 h-5"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M10 16V14.0003M10 14.0003V12M10 14.0003L12 14.0005M10 14.0003L8 14M21 12V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H3M21 12V16M21 12H19C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16H21M21 16V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8V8M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V8"
				stroke={color || mainColor.textMain}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
};
