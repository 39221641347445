import React, { ReactNode } from "react";
import { BUTTON_TYPE } from "utils";

interface IButton {
	onClick: () => void;
	children: ReactNode;
	variant?: string;
	className?: string;
	disabled?: boolean;
}

const Button = ({
	onClick,
	children,
	variant,
	className,
	disabled,
}: IButton) => {
	const buttonStyle = `${
		variant !== BUTTON_TYPE.OUTLINED
			? "inline-flex items-center justify-center h-[42px] rounded-[12px] border border-primary py-3 px-5 text-center font-medium text-sm text-white hover:bg-opacity-90 lg:px-8 xl:px-5 disabled:bg-gray-400 disabled:border-none bg-primary"
			: "inline-flex items-center justify-center h-[42px] rounded-[12px] border border-primary py-3 px-5 text-center font-medium text-sm hover:bg-slate-100 lg:px-8 xl:px-5 text-primary disabled:bg-gray-400 disabled:border-none"
	} ${className || ""}`;

	return (
		<button
			onClick={onClick}
			type="button"
			className={buttonStyle}
			disabled={disabled}>
			{children}
		</button>
	);
};

export default Button;
