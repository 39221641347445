import React, { useCallback, useEffect, useState } from "react";
import { useDashboard, useLayout } from "hooks";
import { avtDf, Icons } from "assets";
import {
	EIllunimusEmail,
	ETypeSearch,
	FormAddCredit,
	FormUpdateUser,
	FormUser,
	IUserTable,
	TParams,
	TParamsSearch,
	TypeLogin,
} from "types";
import {
	ModalDelete,
	ModalAddCredit,
	ModalUpdateUser,
	ModalUser,
} from "components/Modal";
import { FORM_ACTION, formatDMY, tableUserHeader } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Button } from "components/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Table, TableRow } from "components";
import { mainColor } from "theme";
import { ExcelExport } from "components/ExcelExport";
import { SearchUser } from "./component";

const User = () => {
	const {
		onGetUser,
		updateUserMutation,
		deleteUserMutation,
		addCreditMutation,
		onExportUser,
	} = useDashboard();
	const {
		setOpenModalUser,
		setOpenModalUpdateUser,
		setOpenModalDelete,
		setOpenModalAddCredit,
	} = useLayout();
	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const { dataUser, isLoading, refetch, totalPage } = onGetUser({
		...params,
	});

	//Export User
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportUser({
		...paramsExport,
	});

	const [filteredData, setFilteredData] = useState(dataUser);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") {
			SnackbarUtils.warning("Search fill or select a search value");
			return;
		}
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataUser);
	}, [searchValue, searchOption, params]);

	const handleResetSearch = () => {
		if (searchValue === "") return;
		setSearchValue("");
	};

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataUser);
	}, [dataUser]);

	const onCreateUser = useCallback(
		(data: FormUser) => {
			//call API update user here
			const newdUserList = [data, ...filteredData];
			setFilteredData(newdUserList);
			setOpenModalUser(false);
			SnackbarUtils.success("Create Successfully");
		},
		[filteredData],
	);

	const onUpdateUser = useCallback(
		(data: FormUpdateUser) => {
			updateUserMutation.mutate(data, {
				onSuccess: () => setOpenModalUpdateUser(false),
			});
		},
		[item],
	);

	const onDeleteUser = useCallback(
		(uid: string) => {
			deleteUserMutation.mutate(uid, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	const onEditCredit = useCallback(
		(formData: FormAddCredit) => {
			addCreditMutation.mutate(formData, {
				onSuccess: () => setOpenModalAddCredit(false),
			});
		},
		[item],
	);

	// const onExportToCSV = () => {
	// 	return;
	// };

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">User management</h1>
			{/* Temporary comment not active function*/}
			{/* <Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => setOpenModalUser(true)}>
				<span>{Icons.PlusWhite()}</span>
				Add new user
			</Button> */}
			<SearchUser
				searchValue={searchValue}
				searchOption={searchOption}
				setSearchOption={setSearchOption}
				setSearchValue={setSearchValue}
				handleSearch={handleSearch}
				handleResetSearch={handleResetSearch}
			/>

			{/* Temporary comment not active function*/}

			<Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => {
					setOpenModalAddCredit(true);
				}}>
				<span>{Icons.CreditPlus(mainColor.primary)}</span>
				Add credits
			</Button>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="users"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tableUserHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((user: IUserTable, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<TableRow>
							<div className="flex items-center space-x-3.5">
								<button
									title="Edit User"
									onClick={() => {
										setOpenModalUpdateUser(true);
										setItem(user);
										setAction(FORM_ACTION.EDIT);
									}}
									className="hover:text-primary">
									{Icons.Edit()}
								</button>
								<button
									title="Delete User"
									onClick={() => {
										setOpenModalDelete(true);
										setItem(user);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</TableRow>
						<TableRow>
							<h5
								title={user.id}
								className="w-[100px] truncate font-medium text-black">
								{user.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black">{user.userName}</h5>
						</TableRow>
						<TableRow>
							<h5
								className={`font-medium text-black ${
									user?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{user.email}
							</h5>
						</TableRow>
						<TableRow>
							<LazyLoadImage
								src={user.avatar || avtDf}
								className="w-[40px] h-[40px]"
							/>
						</TableRow>
						<TableRow>
							<p className="text-black">{user.role}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{user.credits}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{user.plan}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{formatDMY(user.startDate)}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{formatDMY(user.expiredDate)}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{user.totalBill}</p>
						</TableRow>
						<TableRow>
							<p
								className={`text-black ${
									user.typeLogin === TypeLogin.GOOGLE
										? "text-primary"
										: "text-warning"
								}`}>
								{user.typeLogin}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black">
								{user.isAgree ? (
									<span className="p-1.5 bg-success text-white rounded-lg">
										True
									</span>
								) : (
									<span className="p-1.5 bg-danger text-white rounded-lg">
										False
									</span>
								)}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black">
								{user.isActive ? (
									<span className="p-1.5 bg-success text-white rounded-lg">
										True
									</span>
								) : (
									<span className="p-1.5 bg-danger text-white rounded-lg">
										False
									</span>
								)}
							</p>
						</TableRow>
						{/* <TableRow>
							<p className="text-black">
								{user.isActiveReferral ? "True" : "False"}
							</p>
						</TableRow> */}
						<TableRow>
							<p className="text-black">{user.daysRemaining}</p>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalUser
				onClose={() => setOpenModalUser(false)}
				onConfirm={onCreateUser}
			/>
			<ModalUpdateUser
				item={item}
				onClose={() => setOpenModalUpdateUser(false)}
				action={action}
				onConfirm={onUpdateUser}
				isLoading={updateUserMutation.isPending}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteUser}
			/>
			<ModalAddCredit
				item={item}
				onClose={() => setOpenModalAddCredit(false)}
				onConfirm={onEditCredit}
				isLoading={addCreditMutation.isPending}
			/>
		</React.Fragment>
	);
};

export default User;
