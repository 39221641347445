import React, { useCallback, useEffect, useState } from "react";
import { useDashboard, useLayout } from "hooks";
import { Icons } from "assets";
import {
	ETypeSearch,
	FormEditRefferal,
	IReferral,
	TParams,
	TParamsSearch,
} from "types";
import {
	FORM_ACTION,
	formatDMY,
	searchReffralOption,
	tableReffralHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Search } from "components/Search";
import { Button } from "components/Button";
import { Dropdown, Table, TableRow } from "components";
import { ModalDelete, ModalEditRefrral } from "components/Modal";
import { ExcelExport } from "components/ExcelExport";

const Referral = () => {
	const {
		onGetReferral,
		createReferralMutation,
		editReferralMutation,
		deleteReferralMutation,
		onExportReferral,
	} = useDashboard();
	const { setOpenModalReferral, setOpenModalDelete } = useLayout();

	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();

	const { dataReferral, isLoading, refetch, totalPage } = onGetReferral({
		...params,
	});
	//Export Referral
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportReferral({
			...paramsExport,
		});
	const [filteredData, setFilteredData] = useState(dataReferral);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataReferral);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataReferral);
	}, [dataReferral]);

	const onCreateReferral = useCallback(
		(data: FormEditRefferal) => {
			createReferralMutation.mutate(data, {
				onSuccess: () => setOpenModalReferral(false),
			});
		},
		[item],
	);

	const onEditReferral = useCallback(
		(data: FormEditRefferal) => {
			editReferralMutation.mutate(data, {
				onSuccess: () => setOpenModalReferral(false),
			});
		},
		[item],
	);

	const onDeleteReferral = useCallback(
		(id: string) => {
			deleteReferralMutation.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);
	// const onExportToCSV = () => {
	// 	return;
	// };

	return (
		<div>
			<h1 className="font-bold text-xl mb-4 ">Referral management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 ">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchReffralOption}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Name, Email"
				/>
				<Button onClick={handleSearch}>Search</Button>
			</div>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="referrals"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tableReffralHeader}
				params={params}
				filteredData={filteredData}
				totalPage={totalPage}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((data: IReferral, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<TableRow>
							<div className="flex items-center space-x-3.5">
								<button
									onClick={() => {
										setItem(data);
										setOpenModalReferral(true);
										setAction(FORM_ACTION.EDIT);
									}}
									className="hover:text-primary">
									{Icons.Edit()}
								</button>
								<button
									onClick={() => {
										setItem(data);
										setOpenModalDelete(true);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.uid}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.email}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{data.code}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{data.type}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{data.active.join(", ")}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{data.checkRemainCode}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.createdAt)}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.updatedAt)}
							</p>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalEditRefrral
				item={item}
				action={action}
				onClose={() => setOpenModalReferral(false)}
				onConfirm={(formData: any) => {
					if (action === FORM_ACTION.EDIT) {
						onEditReferral(formData);
					} else {
						onCreateReferral(formData);
					}
				}}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteReferral}
			/>
		</div>
	);
};

export default Referral;
