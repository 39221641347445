import { Icons } from "assets";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import React from "react";

interface IModalDelete {
	item: any;
	onClose: () => void;
	onConfirm: (item: any) => void;
	isLoading?: boolean;
}

const modalPlan = ({ item, onClose, onConfirm, isLoading }: IModalDelete) => {
	const { openModalDelete, setOpenModalDelete } = useLayout();

	return (
		<>
			{openModalDelete && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => !isLoading && setOpenModalDelete(false)}>
						<div className="mb-4 flex gap-2 flex-col bg-white px-8 py-6 rounded-lg">
							<div className="mb-4 flex  gap-1 bg-white">
								Do you want to delete selected item?
							</div>
							<div className="flex flex-row gap-1 h-[40px]">
								<button
									disabled={isLoading}
									onClick={onClose}
									className="inline-flex items-center justify-center rounded-full border border-primary py-3 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10 text-primary w-[140px]">
									Cancel
								</button>
								<button
									disabled={isLoading}
									onClick={() => onConfirm(item.id)}
									type="button"
									className="inline-flex items-center gap-2 justify-center rounded-full bg-primary border border-primary py-3 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 disabled:bg-gray-400 disabled:border-none w-[140px] max-w-[140px]">
									Delete {isLoading && <span>{Icons.Spinner()}</span>}
								</button>
							</div>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalPlan;
