import React, { useState } from "react";
import ClickOutside from "components/Header/ClickOutside";
import { Button, Input } from "components";
import { useLayout } from "hooks";
import { FormPlan } from "types";
import { BUTTON_TYPE, FORM_ACTION, planSchema } from "utils";
import { Icons } from "assets";

interface IModalPlan {
	item: any;
	onClose: () => void;
	action: string;
	onConfirm: (formData: FormPlan) => void;
	isLoading?: boolean;
}

const modalPlan = ({
	item,
	onClose,
	action,
	isLoading,
	onConfirm,
}: IModalPlan) => {
	const { openModalPlan, setOpenModalPlan } = useLayout();
	const [formData, setFormData] = useState<FormPlan>({
		planId: "",
		packageName: "",
		subTitle: "",
		price: 0,
		priceSale: 0,
		unit: "",
		description: "",
		sortType: 0,
	});

	const [errors, setErrors] = useState({});

	React.useEffect(() => {
		if (item && action !== FORM_ACTION.CREATE) {
			setFormData({
				planId: item.id,
				packageName: item.packageName,
				subTitle: item.subTitle,
				price: item.price || 0,
				priceSale: item.priceSale || 0,
				unit: item.unit,
				description: item.description.join(", "),
				sortType: item.sortType,
			});
		}
		if (action === FORM_ACTION.CREATE) {
			setFormData({
				planId: "",
				packageName: "",
				subTitle: "",
				price: 0,
				priceSale: 0,
				unit: "",
				description: "",
				sortType: 0,
			});
		}
	}, [item, openModalPlan]);

	const handleSubmit = async () => {
		try {
			await planSchema.validate(formData, { abortEarly: false });
			onConfirm({
				...formData,
				description: formData.description
					.split(",")
					.map((itemDesc: string) => itemDesc.trim())
					.filter((itemDesc: string) => itemDesc !== ""),
			});
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<>
			{openModalPlan && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalPlan(false)}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded min-w-[500px]">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE ? "Create Plan" : "Edit Plan"}
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Package name"
									type="text"
									formData={formData}
									placeholder="Enter package name"
									name="packageName"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Sub title"
									type="text"
									formData={formData}
									placeholder="Enter sub title"
									name="subTitle"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Price"
									type="number"
									formData={formData}
									placeholder="Enter price"
									name="price"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="w-full mb-4">
									<label className="mb-2 block font-bold text-black">
										Unit
									</label>
									<select
										disabled={isLoading}
										className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										value={formData.unit}
										onChange={(e: any) =>
											setFormData({ ...formData, unit: e.target.value })
										}>
										<option value="" disabled>
											Select unit
										</option>
										<option value="$">$</option>
										<option value="VND">VND</option>
									</select>
								</div>
								<Input
									disabled={isLoading}
									label="Price sale"
									type="number"
									formData={formData}
									placeholder="Enter price sale"
									name="priceSale"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Description"
									type="textarea"
									formData={formData}
									placeholder="Enter description"
									name="description"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
									helperText="You can enter multiple values, each value separated by a comma."
								/>
								<Input
									disabled={isLoading}
									label="Sort type"
									type="number"
									formData={formData}
									placeholder="Enter sort type"
									name="sortType"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalPlan;
