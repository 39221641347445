import React, { FC, memo, useCallback, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "components/Button";
import { BUTTON_TYPE } from "utils";
import { Icons } from "assets";
import { TParamsSearch } from "types";

interface IExportExcelProps {
	fileName: string;
	totalPage?: number;
	getData?: any;
	params?: any;
	setParams?: any;
	isLoading: boolean;
}

const ExcelExport: FC<IExportExcelProps> = ({
	fileName,
	totalPage,
	getData,
	params,
	setParams,
	isLoading,
}) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [fromPage, setFromPage] = useState<number>(1);
	const [toPage, setToPage] = useState<number>(1);

	const exportToExcel = useCallback(async () => {
		if (fromPage < 1) {
			alert("Start page must be greater than or equal 1.");
			return;
		}
		if (toPage < fromPage) {
			alert("End page must be greater than or equal to start page.");
			return;
		}
		try {
			await setParams({
				...params,
				page: fromPage,
				limit: (toPage - fromPage) * 10,
			});
			const exportData = await getData?.();
			const worksheet = XLSX.utils.json_to_sheet(exportData.data.data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			const blob = new Blob([excelBuffer], {
				type: "application/octet-stream",
			});
			saveAs(blob, `${fileName}.xlsx`);
		} catch (err) {
			console.log(err);
		}
	}, [params, fromPage, toPage]);

	return (
		<div className="relative">
			<button
				type="button"
				disabled={isLoading}
				className=" inline-flex items-center justify-center h-[42px] rounded-[12px] border border-primary py-3 px-5 text-center font-medium text-sm text-white hover:bg-opacity-90 lg:px-8 xl:px-5 disabled:bg-gray-400 disabled:border-none bg-primary gap-2"
				onClick={(e: any) => {
					e.preventDefault();
					setShowDropdown((prev) => !prev);
					if (showDropdown) {
						setFromPage(1);
						setToPage(1);
					}
				}}>
				<span>{Icons.Download()}</span> Export to Excel
			</button>
			{showDropdown && (
				<div className="absolute shadow-xl z-9999 top-full right-0 bg-white p-4 w-[450px] rounded-md text-center">
					<h3 className="text-black mb-4 font-bold">
						Please fill pages you want to export
					</h3>
					<form className="flex mb-4 w-full gap-2">
						<div className="w-full flex items-center">
							<label className="w-1/2 mb-2 block text-black">
								From page <span>{`(min = 1)`}</span>
							</label>
							<input
								type="number"
								value={fromPage}
								onChange={(e) => setFromPage(Number(e.target.value))}
								min={1}
								className="w-1/2 rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-4 pr-4 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
								placeholder="Enter start page"
							/>
						</div>
						<div className="w-full flex items-center">
							<label className="w-1/2 mb-2 block text-black mr-2">
								To page <span>{`(max = ${totalPage})`}</span>
							</label>
							<input
								type="number"
								value={toPage}
								onChange={(e) => setToPage(Number(e.target.value))}
								min={fromPage}
								max={totalPage}
								className="w-1/2 rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-4 pr-4 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
								placeholder="Enter end page"
							/>
						</div>
					</form>
					<div className="flex justify-end gap-1">
						<div>
							<Button
								disabled={isLoading}
								variant={BUTTON_TYPE.OUTLINED}
								onClick={() => {
									setShowDropdown(false);
									setFromPage(1);
									setToPage(1);
								}}
								className="w-[100px]">
								Cancel
							</Button>
						</div>
						<div>
							<Button
								disabled={isLoading}
								onClick={exportToExcel}
								className="w-[100px] flex items-center gap-2">
								Confirm {isLoading && <span>{Icons.Spinner()}</span>}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(ExcelExport);
