// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { dashboardService } from "services";
import { TParamsSearch } from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useGeneration = () => {
	const queryClient = useQueryClient();
	//HAIR STYLE
	const onGetHairStyle = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.HAIR_STYLE, params.page, params.search],
			queryFn: () => dashboardService.getHairStyle({ ...params }),
		});

		return {
			hairStyleData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const onExportHairStyle = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_HAIR_STYLE, params.page, params.limit],
			queryFn: () => dashboardService.getHairStyle({ ...params }),
		});

		return {
			hairStyleExportData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const createHairStyleMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.createHairStyle(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.HAIR_STYLE] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editHairStyleMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.editHairStyle(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.HAIR_STYLE] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteHairStyleMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deleteHairStyle(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.HAIR_STYLE] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//GEN ME
	const onGetGenMe = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.GEN_ME, params.page, params.search],
			queryFn: () => dashboardService.getGenMe({ ...params }),
		});

		return {
			genMeData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const onExportGenMe = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_GEN_ME, params.page, params.limit],
			queryFn: () => dashboardService.getGenMe({ ...params }),
		});

		return {
			genMeExportData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const createGenMeMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.createGenMe(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.GEN_ME] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editGenMeMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.editGenMe(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.GEN_ME] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteGenMeMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.deleteGenMe(formData),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.GEN_ME] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});

	//MODEL BODY
	const onGetModelBody = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.MODEL_BODY, params.page, params.search],
			queryFn: () => dashboardService.getModelBody({ ...params }),
		});

		return {
			modelBodyData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const onExportModelBody = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_MODEL_BODY, params.page, params.limit],
			queryFn: () => dashboardService.getModelBody({ ...params }),
		});

		return {
			modelExportBodyData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const createModelBodyMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.createModelBody(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.MODEL_BODY] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editModelBodyMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.editModelBody(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.MODEL_BODY] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteModelBodyMutation = useMutation({
		mutationFn: (formData: any) => dashboardService.deleteModelBody(formData),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.MODEL_BODY] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});

	return {
		onGetHairStyle,
		createHairStyleMutation,
		editHairStyleMutation,
		deleteHairStyleMutation,
		onGetGenMe,
		createGenMeMutation,
		editGenMeMutation,
		deleteGenMeMutation,
		onGetModelBody,
		createModelBodyMutation,
		editModelBodyMutation,
		deleteModelBodyMutation,
		onExportHairStyle,
		onExportGenMe,
		onExportModelBody,
	};
};
