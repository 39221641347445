import React, { ReactNode } from "react";

interface TableRowProps {
	children: ReactNode;
}

const TableRow: React.FC<TableRowProps> = ({ children }) => {
	return <td className="border-b border-[#eee] py-5 px-4">{children}</td>;
};

export default TableRow;
