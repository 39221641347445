import React, { useCallback, useEffect, useState } from "react";
import { useDashboard, useLayout } from "hooks";
import dayjs from "dayjs";
import { Button, Dropdown, Table, TableRow } from "components";
import { ETypeSearch, FormPlan, IPlan, TParams, TParamsSearch } from "types";
import {
	BUTTON_TYPE,
	FORM_ACTION,
	typePlanOptions,
	tablePlanHeader,
} from "utils";
import { Icons } from "assets";
import { ModalDelete, ModalPlan } from "components/Modal";
import { ExcelExport } from "components/ExcelExport";

const Plan = () => {
	const {
		onGetPlan,
		createPlanMutation,
		editPlanMutation,
		deletePlanMutation,
		onExportPlan,
	} = useDashboard();
	const { setOpenModalPlan, setOpenModalDelete } = useLayout();
	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});
	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");

	const { dataPlan, isLoading, refetch, totalPage } = onGetPlan({
		...params,
	});
	//Export Plan
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportPlan({
		...paramsExport,
	});
	const [filteredData, setFilteredData] = useState<any[]>(dataPlan);

	const handleSearch = useCallback(() => {
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: ETypeSearch.PACKAGE_NAME,
		});
		refetch();
		setFilteredData(dataPlan);
	}, [searchValue, params]);

	const handleResetSearch = () => {
		if (searchValue === "") return;
		setSearchValue("");
	};

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	React.useEffect(() => {
		setFilteredData(dataPlan);
	}, [dataPlan]);

	const onCreatePlan = useCallback(
		(formData: FormPlan) => {
			createPlanMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalPlan(false);
				},
			});
		},
		[item],
	);

	const onEditPlan = useCallback(
		(formData: FormPlan) => {
			editPlanMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalPlan(false);
				},
			});
		},
		[item],
	);

	const onDeletePlan = useCallback(
		(id: string) => {
			deletePlanMutation.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Plan management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 items-center">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={typePlanOptions}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				</div>
				<Button onClick={handleSearch}>Search</Button>
				<div title="Reset Search">
					<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
						{Icons.Reload()}
					</Button>
				</div>
			</div>
			<Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => {
					setAction(FORM_ACTION.CREATE);
					setOpenModalPlan(true);
					setOpenModalPlan(true);
				}}>
				<span>{Icons.PlusWhite()}</span>
				Add new plan
			</Button>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="plans"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tablePlanHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData &&
					filteredData?.map((plan: IPlan, key: number) => (
						<tr
							key={key}
							className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
							<TableRow>
								<div className="flex items-center space-x-3.5">
									<button
										onClick={() => {
											setAction(FORM_ACTION.EDIT);
											setItem(plan);
											setOpenModalPlan(true);
										}}
										className="hover:text-primary">
										{Icons.Edit()}
									</button>
									<button
										onClick={() => {
											setOpenModalDelete(true);
											setItem(plan);
										}}
										className="hover:text-primary">
										{Icons.Delete()}
									</button>
								</div>
							</TableRow>
							<TableRow>
								<p title={plan.id} className="w-[100px] truncate text-black">
									{plan.id}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{plan.packageName}</p>
							</TableRow>
							<TableRow>
								<p className="text-black w-[200px]">{plan.subTitle}</p>
							</TableRow>
							<TableRow>
								<p className="text-black w-[200px]">
									{plan.description?.join(", ")}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{plan.price}</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{plan.priceSale}</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{plan.sortType}</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{plan.unit}</p>
							</TableRow>
							<TableRow>
								<p className="text-black">
									{dayjs(plan.createdAt).format("DD/MM/YYYY")}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black">
									{dayjs(plan.updatedAt).format("DD/MM/YYYY")}
								</p>
							</TableRow>
						</tr>
					))}
			</Table>
			<ModalPlan
				item={item}
				action={action}
				onClose={() => setOpenModalPlan(false)}
				onConfirm={(formData: any) => {
					if (action === FORM_ACTION.EDIT) {
						onEditPlan(formData);
					} else {
						onCreatePlan(formData);
					}
				}}
				isLoading={createPlanMutation.isPending || editPlanMutation.isPending}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeletePlan}
				isLoading={deletePlanMutation.isPending}
			/>
		</React.Fragment>
	);
};

export default Plan;
