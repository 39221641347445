import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { Icons, logo } from "assets";
import { ROUTES } from "utils";
import { mainColor } from "theme";
interface SidebarProps {
	sidebarOpen: boolean;
	setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
	const location = useLocation();
	const { pathname } = location;

	const trigger = useRef<any>(null);
	const sidebar = useRef<any>(null);

	const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
	const [sidebarExpanded, setSidebarExpanded] = useState(
		storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
	);

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			if (!sidebar.current || !trigger.current) return;
			if (
				!sidebarOpen ||
				sidebar.current.contains(target) ||
				trigger.current.contains(target)
			)
				return;
			setSidebarOpen(false);
		};
		document.addEventListener("click", clickHandler);
		return () => document.removeEventListener("click", clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: KeyboardEvent) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener("keydown", keyHandler);
		return () => document.removeEventListener("keydown", keyHandler);
	});

	useEffect(() => {
		localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
		if (sidebarExpanded) {
			document.querySelector("body")?.classList.add("sidebar-expanded");
		} else {
			document.querySelector("body")?.classList.remove("sidebar-expanded");
		}
	}, [sidebarExpanded]);

	return (
		<aside
			ref={sidebar}
			className={`absolute left-0 top-0 z-99 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
				sidebarOpen ? "translate-x-0" : "-translate-x-full"
			}`}>
			{/* <!-- SIDEBAR HEADER --> */}
			<div className="flex items-center justify-between gap-2 px-6 mt-5">
				<NavLink to="/">
					<img src={logo} alt="Logo" className="h-[60px]" />
				</NavLink>

				<button
					ref={trigger}
					onClick={() => setSidebarOpen(!sidebarOpen)}
					aria-controls="sidebar"
					aria-expanded={sidebarOpen}
					className="block lg:hidden">
					{Icons.Menu()}
				</button>
			</div>
			{/* <!-- SIDEBAR HEADER --> */}

			<div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
				{/* <!-- Sidebar Menu --> */}
				<nav className="mt-5 py-3 px-4 lg:mt-9 lg:px-6">
					{/* <!-- Menu Group --> */}
					<div>
						<ul className="mb-6 flex flex-col gap-1.5">
							{/* <!-- Menu Item Dashboard --> */}
							<SidebarLinkGroup
								activeCondition={
									pathname === "/" || pathname.includes("dashboard")
								}>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													(pathname === "/" ||
														pathname.includes("dashboard")) &&
													"bg-graydark dark:bg-meta-4"
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}>
												{Icons.Dashboard()}
												Dashboard
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && "rotate-180"
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && "hidden"
												}`}>
												<ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
													{/* <li>
														<NavLink
															to="/"
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Analytics
														</NavLink>
													</li> */}
													<li>
														<NavLink
															to="/"
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Users
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.DASHBOARD.PLAN}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Plans
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.DASHBOARD.GALLERY}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Galleries
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.DASHBOARD.CONTACT_US}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Contact us
														</NavLink>
													</li>
													<li>
														<ul>
															<SidebarLinkGroup
																activeCondition={
																	pathname.includes("referral") ||
																	pathname.includes("reference")
																}>
																{(onClick, isOpen) => (
																	<React.Fragment>
																		<NavLink
																			to={ROUTES.APP_ROOT.DASHBOARD.REFERRAL}
																			onClick={(e) => {
																				e.preventDefault();
																				sidebarExpanded
																					? onClick()
																					: setSidebarExpanded(true);
																			}}
																			className={`group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ${
																				(pathname.includes("referral") &&
																					"!text-white") ||
																				(pathname.includes("reference") &&
																					"!text-white")
																			}`}>
																			Referral{" "}
																			<svg
																				className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
																					isOpen && "rotate-180"
																				}`}
																				width="20"
																				height="20"
																				viewBox="0 0 20 20"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg">
																				<path
																					fillRule="evenodd"
																					clipRule="evenodd"
																					d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
																					fill=""
																				/>
																			</svg>
																		</NavLink>
																		<div
																			className={`translate transform overflow-hidden ${
																				!isOpen && "hidden"
																			}`}>
																			<ul className="mt-4 flex flex-col gap-2.5 pl-6">
																				<li>
																					<NavLink
																						to={
																							ROUTES.APP_ROOT.DASHBOARD.REFERRAL
																						}
																						className={({ isActive }) =>
																							"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																							(isActive && "!text-white")
																						}>
																						General Referral
																					</NavLink>
																				</li>
																				<li>
																					<NavLink
																						to={
																							ROUTES.APP_ROOT.DASHBOARD
																								.REFERENCE
																						}
																						className={({ isActive }) =>
																							"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																							(isActive && "!text-white")
																						}>
																						Reference
																					</NavLink>
																				</li>
																			</ul>
																		</div>
																	</React.Fragment>
																)}
															</SidebarLinkGroup>
														</ul>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.DASHBOARD.HISTORY}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Histories
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
							{/* <!-- Menu Item Transactions --> */}
							<SidebarLinkGroup
								activeCondition={pathname.includes("transactions")}>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													pathname.includes("generations") &&
													"bg-graydark dark:bg-meta-4"
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}>
												{Icons.Template(mainColor.primary)}
												Generations
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && "rotate-180"
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && "hidden"
												}`}>
												<ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.GENERATION.HAIR_STYLE}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Hair style
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.GENERATION.GEM_ME}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Gen me
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.GENERATION.MODEL_BODY}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Model body
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
							<SidebarLinkGroup
								activeCondition={pathname.includes("transactions")}>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													pathname.includes("transactions") &&
													"bg-graydark dark:bg-meta-4"
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}>
												{Icons.Transaction(mainColor.primary)}
												Transactions
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && "rotate-180"
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && "hidden"
												}`}>
												<ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.TRANSACTION.TRANSACTION_PLAN}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Plan
														</NavLink>
													</li>
													<li>
														<NavLink
															to={
																ROUTES.APP_ROOT.TRANSACTION.TRANSACTION_CREDIT
															}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Credit
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
							{/* <!-- Menu Item Settings --> */}
							<SidebarLinkGroup activeCondition={pathname.includes("settings")}>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													pathname.includes("settings") &&
													"bg-graydark dark:bg-meta-4"
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}>
												{Icons.Setting(mainColor.primary)}
												Settings
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && "rotate-180"
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && "hidden"
												}`}>
												<ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.SETTINGS.PLANS_CREDIT}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Plans Credit
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.SETTINGS.PLANS_UNIT}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Plans Unit
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.SETTINGS.CHARGE_SERVICE}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Charge services
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.SETTINGS.NOTIFICATION}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Notifications
														</NavLink>
													</li>
													<li>
														<NavLink
															to={ROUTES.APP_ROOT.SETTINGS.TERMS_AND_CONDITIONS}
															className={({ isActive }) =>
																"group relative flex items-center gap-2.5 px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white " +
																(isActive && "!text-white")
															}>
															Terms and Conditions
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
							{/* <!-- Menu Item Settings --> */}
							<li>
								<NavLink
									to={ROUTES.APP_ROOT.NOTIFICATIONS}
									className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname.includes("notifications") &&
										"bg-graydark dark:bg-meta-4"
									}`}>
									{Icons.Notification(mainColor.primary)}
									Notifications
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
				{/* <!-- Sidebar Menu --> */}
			</div>
		</aside>
	);
};

export default Sidebar;
