// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { dashboardService } from "services/dashboardService";
import {
	FormAddCredit,
	FormEditRefferal,
	FormPlan,
	FormUpdateUser,
	TParamsSearch,
} from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useDashboard = () => {
	const queryClient = useQueryClient();
	//USER
	const onGetUser = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.USER, params.page, params.search],
			queryFn: () => dashboardService.getUser(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportUser = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_USER, params.page, params.limit],
			queryFn: () => dashboardService.getUser(params),
		});
		return {
			dataExportUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const updateUserMutation = useMutation({
		mutationFn: (formData: FormUpdateUser) =>
			dashboardService.updateUser(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const deleteUserMutation = useMutation({
		mutationFn: (uid: string) => dashboardService.deleteUser(uid),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Delete successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	const addCreditMutation = useMutation({
		mutationFn: (formData: FormAddCredit) =>
			dashboardService.addCredit(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	//Plan
	const onGetPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.PLAN, params.page, params.search],
			queryFn: () => dashboardService.getPlan(params),
		});
		return {
			dataPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_PLAN, params.page, params.limit],
			queryFn: () => dashboardService.getPlan(params),
		});
		return {
			dataExportPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const createPlanMutation = useMutation({
		mutationFn: (formData: FormPlan) => dashboardService.createPlan(formData),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Create successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editPlanMutation = useMutation({
		mutationFn: (formData: FormPlan) => dashboardService.editPlan(formData),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deletePlanMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deletePlan(id),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Delete successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//Gallery
	const onGetGallery = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.GALLERY, params.page, params.search],
			queryFn: () => dashboardService.getGallery(params),
		});

		return {
			dataGallery: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportGallery = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_GALLERY, params.page, params.limit],
			queryFn: () => dashboardService.getGallery(params),
		});

		return {
			dataExportGallery: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const deleteGalleryMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deleteGallery(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.GALLERY] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//Contact us
	const onGetContactUs = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.CONTACT_US, params.page, params.search],
			queryFn: () => dashboardService.getContactUs(params),
		});

		return {
			dataContactUs: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportContactUs = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_CONTACT_US, params.page, params.limit],
			queryFn: () => dashboardService.getContactUs(params),
		});

		return {
			dataExportContactUs: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	//Referral
	const onGetReferral = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.REFERRAL, params.page, params.search],
			queryFn: () => dashboardService.getReferral(params),
		});
		return {
			dataReferral: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportReferral = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_REFERRAL, params.page, params.limit],
			queryFn: () => dashboardService.getReferral(params),
		});
		return {
			dataExportReferral: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetReference = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.REFERENCE, params.page, params.search],
			queryFn: () => dashboardService.getReference(params),
		});
		return {
			dataReference: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportReference = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_REFERRENCE, params.page, params.limit],
			queryFn: () => dashboardService.getReference(params),
		});
		return {
			dataExportReference: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const createReferralMutation = useMutation({
		mutationFn: (formData: FormEditRefferal) =>
			dashboardService.createReferral(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const editReferralMutation = useMutation({
		mutationFn: (formData: FormEditRefferal) =>
			dashboardService.editReferral(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const deleteReferralMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deleteReferral(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//History
	const onGetHistory = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.HISTORY, params.page, params.search],
			queryFn: () => dashboardService.getHistory(params),
		});
		return {
			dataHistory: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportHistory = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_HISTORY, params.page, params.limit],
			queryFn: () => dashboardService.getHistory(params),
		});
		return {
			dataExportHistory: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	//Credit

	return {
		onGetUser,
		updateUserMutation,
		deleteUserMutation,
		addCreditMutation,
		onGetPlan,
		createPlanMutation,
		editPlanMutation,
		deletePlanMutation,
		onGetGallery,
		deleteGalleryMutation,
		onGetContactUs,
		onGetReferral,
		onGetReference,
		createReferralMutation,
		editReferralMutation,
		deleteReferralMutation,
		onGetHistory,
		onExportUser,
		onExportContactUs,
		onExportGallery,
		onExportHistory,
		onExportPlan,
		onExportReference,
		onExportReferral,
	};
};
