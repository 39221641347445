import React, { ReactNode, useState } from "react";
import TableHeader from "./TableHeader";
import TableLoading from "./TableLoading";
import { Icons } from "assets";
import useDragScroll from "hooks/useDragScroll";

interface TableProps {
	dataHeader: object;
	filteredData: any[];
	setFilteredData: (data: any[]) => void;
	isLoading: boolean;
	children: ReactNode;
	params: any;
	totalPage?: number;
	onPreviousClick?: () => void;
	onNextClick?: () => void;
	isAction?: boolean;
}

const Table: React.FC<TableProps> = ({
	dataHeader,
	filteredData,
	setFilteredData,
	isLoading,
	children,
	params,
	totalPage,
	onPreviousClick,
	onNextClick,
	isAction,
}) => {
	const { scrollRef, onMouseDown, onMouseLeave, onMouseUp, onMouseMove } =
		useDragScroll();
	const [sortingColumn, setSortingColumn] = useState<any[]>([]);

	const onSorting = (column: string, changeSortingColumn = true) => {
		const isNumber = typeof filteredData?.[0]?.[column] === "number";
		if (sortingColumn?.includes(column) && changeSortingColumn) {
			const sortData = filteredData?.slice().sort((a: any, b: any) => {
				if (isNumber) {
					return b[column] - a[column];
				}
				return b[column]?.toString().localeCompare(a[column]?.toString());
			});

			if (changeSortingColumn) {
				setSortingColumn([]);
				setFilteredData(sortData);
			}
		} else {
			const sortData = filteredData?.slice().sort((a: any, b: any) => {
				if (isNumber) {
					return a[column] - b[column];
				}
				return a[column]?.toString().localeCompare(b[column]?.toString());
			});

			if (changeSortingColumn) {
				setFilteredData(sortData);
				setSortingColumn([`${column}`]);
			}
		}
	};

	return (
		<div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1 relative">
			<div
				className="max-w-full overflow-x-auto cursor-grab"
				ref={scrollRef}
				onMouseDown={onMouseDown}
				onMouseLeave={onMouseLeave}
				onMouseUp={onMouseUp}
				onMouseMove={onMouseMove}>
				<table className="w-full table-auto">
					<thead>
						<tr className=" text-left lg:ml-8 relative truncate ">
							{isAction && (
								<th className="py-3 px-4 font-medium text-white dark:text-white bg-header hover:bg-opacity-95">
									Actions
								</th>
							)}
							<TableHeader
								dataHeader={dataHeader}
								onSorting={onSorting}
								sortingColumn={sortingColumn}
							/>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<TableLoading />
						) : filteredData?.length > 0 ? (
							children
						) : (
							<tr className="bg-gray-200 text-center dark:bg-graydark">
								<td
									colSpan={Object.keys(dataHeader).length + (isAction ? 1 : 0)}
									className="p-4">
									No data found
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{totalPage ? (
				<div className="flex justify-between px-6 py-3">
					<button
						className=" disabled:text-gray-400 disabled:hover:bg-[#EDEFF1] flex items-center gap-1 justify-center rounded bg-[#EDEFF1] py-1.5 px-3 text-xs font-medium text-black hover:bg-primary hover:text-white dark:bg-graydark dark:text-white dark:hover:bg-primary dark:hover:text-white"
						disabled={params.page === 1}
						onClick={onPreviousClick}>
						{Icons.ArrowLeft()} Previous
					</button>
					<div className="flex items-center gap-2">
						<div>
							{totalPage && (
								<>
									Page <strong>{params.page}</strong> of{" "}
									<strong>{totalPage}</strong>
								</>
							)}
						</div>
						<button
							className=" disabled:text-gray-400 disabled:hover:bg-[#EDEFF1] flex items-center gap-1 justify-center rounded bg-[#EDEFF1] py-1.5 px-3 text-xs font-medium text-black hover:bg-primary hover:text-white dark:bg-graydark dark:text-white dark:hover:bg-primary dark:hover:text-white"
							disabled={params.page >= totalPage}
							onClick={onNextClick}>
							Next {Icons.ArrowRight()}
						</button>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Table;
