/** @format */

export const END_POINT = {
	AUTH: {
		LOGIN: "/admin/login",
		REFRESH_TOKEN: "/auth/refresh-token",
		CHANGE_PASSWORD: "/auth/change-password",
	},
	USER: {
		GET_PROFILE: "/admin/me",
		LOGOUT: "admin/logout",
	},
	DASHBOARD: {
		GET_USERS: "/user/admin",
		EDIT_USER: "/user/admin/edit",
		DELETE_USER: "/user/admin/delete",

		GET_PLAN: "/plan/admin",
		EDIT_PLAN: "/plan/admin/edit",
		DELETE_PLAN: "/plan/admin/delete",

		ADD_CREDIT: "/user/admin/add-credits",

		GET_GALLERY: "/gallery/admin",
		EDIT_GALLERT: "/gallery/admin/edit",
		DELETE_GALLERY: "/gallery/admin/delete",

		GET_CONTACT_US: "/contact-us/admin",

		GET_REFERRAL: "/refrral/admin",
		CREATE_REFERRAL: "refrral/admin/create",
		EDIT_REFERRAL: "refrral/admin/edit",
		DELETE_REFERRAL: "refrral/admin/delete",

		GET_REFERENCE: "/refrral/admin/referrence",

		GET_HISTORY: "/history/admin",
	},
	TRANSACTION: {
		GET_TRANSACTION_PLAN: "/transaction-plans/admin",
		GET_TRANSACTION_CREDIT: "/transaction-credits/admin",
	},
	GENERATION: {
		GET_HAIR_STYLE: "/hair-style/admin",
		CREATE_HAIR_STYLE: "/hair-style/admin/create",
		EDIT_HAIR_STYLE: "/hair-style/admin/edit",
		DELETE_HAIR_STYLE: "/hair-style/admin/delete",

		GET_GEN_ME: "/gen-me/admin",
		CREATE_GEN_ME: "/gen-me/admin/create",
		EDIT_GEN_ME: "/gen-me/admin/edit",
		DELETE_GEN_ME: "/gen-me/admin/delete",

		GET_MODEL_BODY: "/model-body/admin",
		CREATE_MODEL_BODY: "/model-body/admin/create",
		EDIT_MODEL_BODY: "/model-body/admin/edit",
		DELETE_MODEL_BODY: "/model-body/admin/delete",
	},
	SETTING: {
		GET_SETTING: "settings/admin",
		EDIT_SETTING: "settings/admin/edit",
	},
	NOTIFICATION: {
		GET_NOTIFICATION: "/notifications/admin",
		CREATE_NOTIFICATION: "/notifications/admin/create",
		EDIT_NOTIFICATION: "/notifications/admin/edit",
		DELETE_NOTIFICATION: "/notifications/admin/delete",
	},
	MERCHANT: {
		//CATEGORY
		GET_CATEGORY: "/clothes/category-merchant",
		CREATE_CATEGORY: "/categories/admin/create-clothes",
		EDIT_CATEGORY: "/categories/admin/edit",
		DELETE_CATEGORY: "/categories/admin/delete",
		//CLOTHES
		GET_CLOTHES: "/clothes/admin/merchant",
		CREATE_CLOTHES: "/clothes/admin/merchant-create",
		EDIT_CLOTHES: "/clothes/admin/merchant-update",
		DELETE_CLOTHES: "/clothes/admin/delete",
	},
};
