/** @format */

import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	openModalGlobal: boolean;
	loadingGlobal: boolean;
	openModalUser: boolean;
	openModalUpdateUser: boolean;
	openModalPlan: boolean;
	openModalPolicy: boolean;
	openModalReferral: boolean;
	openModalNoti: boolean;
	openModalExpireSession: boolean;
	openModalAddCredit: boolean;
	openModalCategory: boolean;
	openModalClothes: boolean;
	openModalHairStyle: boolean;
	openModalGenMe: boolean;
	openModalModelBody: boolean;
	openModalDelete: boolean;
};

type Actions = {
	setOpenModalGlobal: (v: boolean) => void;
	setLoadingGlobal: (v: boolean) => void;
	setOpenModalUser: (v: boolean) => void;
	setOpenModalUpdateUser: (v: boolean) => void;
	setOpenModalPlan: (v: boolean) => void;
	setOpenModalPolicy: (v: boolean) => void;
	setOpenModalReferral: (v: boolean) => void;
	setOpenModalNoti: (v: boolean) => void;
	setOpenModalExpireSession: (v: boolean) => void;
	setOpenModalAddCredit: (v: boolean) => void;
	setOpenModalCategory: (v: boolean) => void;
	setOpenModalClothes: (v: boolean) => void;
	setOpenModalHairStyle: (v: boolean) => void;
	setOpenModalGenMe: (v: boolean) => void;
	setOpenModalModelBody: (v: boolean) => void;
	setOpenModalDelete: (v: boolean) => void;
};

const initialState: State = {
	openModalGlobal: false,
	loadingGlobal: false,
	openModalUser: false,
	openModalUpdateUser: false,
	openModalPlan: false,
	openModalPolicy: false,
	openModalReferral: false,
	openModalNoti: false,
	openModalExpireSession: false,
	openModalAddCredit: false,
	openModalCategory: false,
	openModalClothes: false,
	openModalHairStyle: false,
	openModalGenMe: false,
	openModalModelBody: false,
	openModalDelete: false,
};

export const useLayout = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setOpenModalGlobal: (v) => set({ openModalGlobal: v }),
			setLoadingGlobal: (v) => set({ loadingGlobal: v }),
			setOpenModalUser: (v) => set({ openModalUser: v }),
			setOpenModalUpdateUser: (v) => set({ openModalUpdateUser: v }),
			setOpenModalPlan: (v) => set({ openModalPlan: v }),
			setOpenModalPolicy: (v) => set({ openModalPolicy: v }),
			setOpenModalReferral: (v) => set({ openModalReferral: v }),
			setOpenModalNoti: (v) => set({ openModalNoti: v }),
			setOpenModalExpireSession: (v) => set({ openModalExpireSession: v }),
			setOpenModalAddCredit: (v) => set({ openModalAddCredit: v }),
			setOpenModalCategory: (v) => set({ openModalCategory: v }),
			setOpenModalClothes: (v) => set({ openModalClothes: v }),
			setOpenModalHairStyle: (v) => set({ openModalHairStyle: v }),
			setOpenModalGenMe: (v) => set({ openModalGenMe: v }),
			setOpenModalModelBody: (v) => set({ openModalModelBody: v }),
			setOpenModalDelete: (v) => set({ openModalDelete: v }),
		}),
		{
			name: "LayoutStore",
		},
	),
);
