import { Icons } from "assets";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import * as React from "react";
import { FormUser } from "types";
import { DATA_USER_TYPE, EUSER_TYPE } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

interface IModalUser {
	onClose: () => void;
	onConfirm: (data: any) => void;
}

const modalUser = ({ onClose, onConfirm }: IModalUser) => {
	const { openModalUser, setOpenModalUser } = useLayout();
	const [formUser, setFormUser] = React.useState<FormUser>({
		userName: "",
		email: "",
		password: "",
		confirmPassword: "",
		role: EUSER_TYPE.USER,
	});
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleSubmit = React.useCallback(
		(data: FormUser) => {
			const isEmptyField = Object.values(data).some((field) => {
				if (typeof field === "string") return field.trim() === "";
				return field === null;
			});

			if (data.password !== data.confirmPassword) {
				SnackbarUtils.warning("Password is must match");
				return;
			}

			if (isEmptyField) {
				SnackbarUtils.warning("Please fill in all required fields");
				return;
			}
			//call api here
			try {
				const dummyData = {
					...data,
					id: "00014c6f1484f42b8085fe09",
					isActive: true,
					createdAt: new Date(),
					updatedAt: new Date(),
					isDeleted: false,
					credits: 5,
					transactionPlans: "66f14c6f1484f42b8085fe01",
				};
				setIsLoading(true);
				setTimeout(() => {
					onConfirm?.(dummyData);
					setIsLoading(false);
				}, 1000);
			} catch (err) {
				console.log(err);
			}
		},
		[formUser],
	);

	React.useEffect(() => {
		setFormUser({
			userName: "",
			email: "",
			password: "",
			confirmPassword: "",
			role: EUSER_TYPE.USER,
		});
	}, [openModalUser]);

	return (
		<>
			{openModalUser && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalUser(false)}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Create User
							</h3>
							<form onSubmit={() => handleSubmit(formUser)} className="">
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										User name
									</label>
									<div className="w-full">
										<input
											id="userName"
											value={formUser.userName}
											onChange={(e) =>
												setFormUser({ ...formUser, userName: e.target.value })
											}
											type="text"
											placeholder="User name"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Email
									</label>
									<div className="w-full">
										<input
											id="email"
											value={formUser.email}
											onChange={(e) =>
												setFormUser({ ...formUser, email: e.target.value })
											}
											type="email"
											autoComplete="username"
											placeholder="Enter Email"
											className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Password
									</label>
									<div className="relative">
										<input
											id="password"
											value={formUser.password}
											onChange={(e) =>
												setFormUser({ ...formUser, password: e.target.value })
											}
											type="password"
											autoComplete="new-password"
											placeholder="Enter password"
											className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>

										<span className="absolute right-4 top-4">
											<svg
												className="fill-current"
												width="22"
												height="22"
												viewBox="0 0 22 22"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<g opacity="0.5">
													<path
														d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
														fill=""
													/>
													<path
														d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
														fill=""
													/>
												</g>
											</svg>
										</span>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Re-type Password
									</label>
									<div className="relative">
										<input
											id="confirmPassword"
											value={formUser.confirmPassword}
											onChange={(e) =>
												setFormUser({
													...formUser,
													confirmPassword: e.target.value,
												})
											}
											type="password"
											autoComplete="new-password"
											placeholder="Re-enter password"
											className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>

										<span className="absolute right-4 top-4">
											<svg
												className="fill-current"
												width="22"
												height="22"
												viewBox="0 0 22 22"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<g opacity="0.5">
													<path
														d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
														fill=""
													/>
													<path
														d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
														fill=""
													/>
												</g>
											</svg>
										</span>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Role
									</label>
									<div className="w-full">
										<select
											value={formUser.role}
											onChange={(e) =>
												setFormUser({
													...formUser,
													role: e.target.value,
												})
											}
											name="roles"
											id="roles"
											className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
											{DATA_USER_TYPE.map((role) => (
												<option key={role.value} value={role.value}>
													{role.name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<button
											onClick={onClose}
											className="inline-flex items-center justify-center rounded-full border border-primary py-3 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10 text-primary">
											Cancel
										</button>
									</div>
									<div>
										<button
											disabled={isLoading}
											onClick={() => handleSubmit(formUser)}
											type="button"
											className="inline-flex items-center gap-2 justify-center rounded-full bg-primary border border-primary py-3 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 disabled:bg-gray-400 disabled:border-none w-[140px] max-w-[140px]">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalUser;
